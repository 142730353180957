'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2/25/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

function hbAuth(session) {
  /**
   * Check whether the user is authorized for access to a given resource.
   * @param {string|Array} requiredAccess The required access permissions.
   * @returns {boolean} Whether the user has the required permissions.
   */
  function isAuthorized(requiredAccess) {
    // Normalize the access content.
    if (!angular.isArray(requiredAccess)) { requiredAccess = [requiredAccess]; }

    // Some content is available to anyone.
    if (requiredAccess.indexOf('*') !== -1) { return true; }

    // Otherwise, if the user is not logged in, automatically return.
    if (!session.isAuthenticated()) { return false; }

    // Make sure the user has the required access permissions.
    for (let a = 0; a < requiredAccess.length; a++) {
      if (session.get().access.indexOf(requiredAccess[a]) === -1) {
        return false;
      }
    }

    return true;
  }

  return { isAuthorized };
}


angular.module('hbServices').factory('hbAuth', [
  // Inject dependencies. AngularJS:
  // First-party (`hb`):
  'hbSession',
  // Supply the constructor:
  hbAuth
]);
