'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2/13/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

const articlesRoot = '/rhkjvsb';

const byLocation = {
  gen: [
    // 1
    {
      title: 'Introduction to the Pentateuch',
      url: `${articlesRoot}/introduction-to-the-pentateuch`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Genesis',
      url: `${articlesRoot}/introduction-to-the-book-of-genesis`,
      source: 'KJVSB'
    },
    {
      title: 'Creation and God’s Glory',
      url: `${articlesRoot}/creation-gods-glory`,
      source: 'KJVSB',
      chapter: 1
    },
    {
      title: 'The Fall of Man',
      url: `${articlesRoot}/fall-of-man`,
      source: 'KJVSB',
      chapter: 3
    },
    {
      title: 'Faith in God',
      url: `${articlesRoot}/faith-in-god`,
      source: 'KJVSB',
      chapter: 15
    },
    {
      title: 'God’s Covenants',
      url: `${articlesRoot}/gods-covenants`,
      source: 'KJVSB',
      chapter: 50
    }
  ],
  exo: [
    // 2
    {
      title: 'Introduction to the Book of Exodus',
      url: `${articlesRoot}/introduction-to-the-book-of-exodus`,
      source: 'KJVSB'
    },
    {
      title: 'God’s Names',
      url: `${articlesRoot}/gods-names`,
      source: 'KJVSB',
      chapter: 3
    },
    {
      title: 'God’s Love and Justice',
      url: `${articlesRoot}/gods-love-justice`,
      source: 'KJVSB',
      chapter: 34
    },
    {
      title: 'God’s Dwelling with Man',
      url: `${articlesRoot}/gods-dwelling-with-man`,
      source: 'KJVSB',
      chapter: 40
    }
  ],
  lev: [
    // 3
    {
      title: 'Introduction to the Book of Leviticus',
      url: `${articlesRoot}/introduction-to-the-book-of-leviticus`,
      source: 'KJVSB'
    },
    {
      title: 'God’s Holiness and Ours',
      url: `${articlesRoot}/gods-holiness-ours`,
      source: 'KJVSB',
      chapter: 11
    }
  ],
  num: [
    // 4
    {
      title: 'Introduction to the Book of Numbers',
      url: `${articlesRoot}/introduction-to-the-book-of-numbers`,
      source: 'KJVSB'
    },
    {
      title: 'God’s Immutability',
      url: `${articlesRoot}/gods-immutability`,
      source: 'KJVSB',
      chapter: 23
    }
  ],
  deu: [
    // 5
    {
      title: 'Introduction to the Book of Deuteronomy',
      url: `${articlesRoot}/introduction-to-the-book-of-deuteronomy`,
      source: 'KJVSB'
    },
    {
      title: 'The Uses of the Moral Law',
      url: `${articlesRoot}/uses-of-the-moral-law`,
      source: 'KJVSB',
      chapter: 5
    }
  ],
  jos: [
    // 6
    {
      title: 'Introduction to the Historical Books',
      url: `${articlesRoot}/introduction-to-the-historical-books`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Joshua',
      url: `${articlesRoot}/introduction-to-the-book-of-joshua`,
      source: 'KJVSB'
    },
    {
      title: 'Canaan and the Inheritance',
      url: `${articlesRoot}/canaan-inheritance`,
      source: 'KJVSB',
      chapter: 11
    }
  ],
  jdg: [
    // 7
    {
      title: 'Introduction to the Book of Judges',
      url: `${articlesRoot}/introduction-to-the-book-of-judges`,
      source: 'KJVSB'
    },
    {
      title: 'The Angel of the Lord',
      url: `${articlesRoot}/angel-lord`,
      source: 'KJVSB',
      chapter: 7
    }
  ],
  rth: [
    // 8
    {
      title: 'Introduction to the Book of Ruth',
      url: `${articlesRoot}/introduction-to-the-book-of-ruth`,
      source: 'KJVSB'
    }
  ],
  '1sa': [
    // 9
    {
      title: 'Introduction to the Books of 1 and 2 Samuel',
      url: `${articlesRoot}/introduction-to-the-books-of-1-and-2-samuel`,
      source: 'KJVSB'
    },
    {
      title: 'Obedience to God’s Will',
      url: `${articlesRoot}/obedience-to-gods-will`,
      source: 'KJVSB',
      chapter: 15
    }
  ],
  '2sa': [
    // 10
    {
      title: 'Introduction to the Book of 2 Samuel',
      url: `${articlesRoot}/introduction-to-the-book-of-2-samuel`,
      source: 'KJVSB'
    },
    {
      title: 'The Son of David',
      url: `${articlesRoot}/son-of-david`,
      source: 'KJVSB',
      chapter: 7
    }
  ],
  '1ki': [
    // 11
    {
      title: 'Introduction to the Books of 1 and 2 Kings',
      url: `${articlesRoot}/introduction-to-the-books-of-1-and-2-kings`,
      source: 'KJVSB'
    },
    {
      title: 'God’s Foreknowledge',
      url: `${articlesRoot}/gods-foreknowledge`,
      source: 'KJVSB',
      chapter: 13
    }
  ],
  '2ki': [
    // 12
    {
      title: 'Introduction to the Book of 2 Kings',
      url: `${articlesRoot}/introduction-to-the-book-of-2-kings`,
      source: 'KJVSB'
    },
    {
      title: 'The Only True God',
      url: `${articlesRoot}/only-true-god`,
      source: 'KJVSB',
      chapter: 19
    }
  ],
  '1ch': [
    // 13
    {
      title: 'Introduction to the Books of 1 and 2 Chronicles',
      url: `${articlesRoot}/introduction-to-the-books-of-1-and-2-chronicles`,
      source: 'KJVSB'
    },
    {
      title: 'Worship by the Word',
      url: `${articlesRoot}/worship-by-the-word`,
      source: 'KJVSB',
      chapter: 15
    }
  ],
  '2ch': [
    // 14
    {
      title: 'Introduction to the Book of 2 Chronicles',
      url: `${articlesRoot}/introduction-to-the-book-of-2-chronicles`,
      source: 'KJVSB'
    },
    {
      title: 'Repentance',
      url: `${articlesRoot}/repentance`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  ezr: [
    // 15
    {
      title: 'Introduction to the Book of Ezra',
      url: `${articlesRoot}/introduction-to-the-book-of-ezra`,
      source: 'KJVSB'
    }
  ],
  neh: [
    // 16
    {
      title: 'Introduction to the Book of Nehemiah',
      url: `${articlesRoot}/introduction-to-the-book-of-nehemiah`,
      source: 'KJVSB'
    },
    {
      title: 'This Evil World',
      url: `${articlesRoot}/this-evil-world`,
      source: 'KJVSB',
      chapter: 4
    }
  ],
  est: [
    // 17
    {
      title: 'Introduction to the Book of Esther',
      url: `${articlesRoot}/introduction-to-the-book-of-esther`,
      source: 'KJVSB'
    }
  ],
  job: [
    // 18
    {
      title: 'Introduction to the Poetic and Wisdom Books',
      url: `${articlesRoot}/introduction-to-the-poetic-and-wisdom-books`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Job',
      url: `${articlesRoot}/introduction-to-the-book-of-job`,
      source: 'KJVSB'
    },
    {
      title: 'Satan',
      url: `${articlesRoot}/satan`,
      source: 'KJVSB',
      chapter: 2
    },
    {
      title: 'God’s Wisdom',
      url: `${articlesRoot}/gods-wisdom`,
      source: 'KJVSB',
      chapter: 12
    }
  ],
  psa: [
    // 19
    {
      title: 'Introduction to the Book of Psalms',
      url: `${articlesRoot}/introduction-to-the-book-of-the-psalms`,
      source: 'KJVSB'
    },
    {
      title: 'The Means of Grace',
      url: `${articlesRoot}/means-of-grace`,
      source: 'KJVSB',
      chapter: 19
    },
    {
      title: 'The Soul',
      url: `${articlesRoot}/soul`,
      source: 'KJVSB',
      chapter: 63
    },
    {
      title: 'God’s Eternity',
      url: `${articlesRoot}/gods-eternity`,
      source: 'KJVSB',
      chapter: 90
    }
  ],
  pro: [
    // 20
    {
      title: 'Introduction to the Book of Proverbs',
      url: `${articlesRoot}/introduction-to-the-book-of-proverbs`,
      source: 'KJVSB'
    },
    {
      title: 'The Authority and Inerrancy of Scripture',
      url: `${articlesRoot}/authority-inerrancy-scripture`,
      source: 'KJVSB',
      chapter: 30
    }
  ],
  ecc: [
    // 21
    {
      title: 'Introduction to the Book of Ecclesiastes',
      url: `${articlesRoot}/introduction-to-the-book-of-ecclesiastes`,
      source: 'KJVSB'
    }
  ],
  sng: [
    // 22
    {
      title: 'Introduction to the Book of Song of Solomon',
      url: `${articlesRoot}/introduction-to-the-book-of-song-of-solomon`,
      source: 'KJVSB'
    }
  ],
  isa: [
    // 23
    {
      title: 'Introduction to the Prophets',
      url: `${articlesRoot}/introduction-to-the-prophets`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Isaiah',
      url: `${articlesRoot}/introduction-to-the-book-of-isaiah`,
      source: 'KJVSB'
    },
    {
      title: 'Christ’s Kingdom',
      url: `${articlesRoot}/christs-kingdom`,
      source: 'KJVSB',
      chapter: 9
    }
  ],
  jer: [
    // 24
    {
      title: 'Introduction to the Book of jeremiah',
      url: `${articlesRoot}/introduction-to-the-book-of-jeremiah`,
      source: 'KJVSB'
    },
    {
      title: 'Total Depravity',
      url: `${articlesRoot}/total-depravity`,
      source: 'KJVSB',
      chapter: 17
    },
    {
      title: 'The Perseverance of the Saints',
      url: `${articlesRoot}/perseverance-of-the-saints`,
      source: 'KJVSB',
      chapter: 32
    }
  ],
  lam: [
    // 25
    {
      title: 'Introduction to the Book of Lamentations',
      url: `${articlesRoot}/introduction-to-the-book-of-lamentations`,
      source: 'KJVSB'
    },
    {
      title: 'God’s Faithfulness',
      url: `${articlesRoot}/gods-faithfulness`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  ezk: [
    // 26
    {
      title: 'Introduction to the Book of Ezekiel',
      url: `${articlesRoot}/introduction-to-the-book-of-ezekiel`,
      source: 'KJVSB'
    },
    {
      title: 'Human Responsibility',
      url: `${articlesRoot}/human-responsibility`,
      source: 'KJVSB',
      chapter: 18
    }
  ],
  dan: [
    // 27
    {
      title: 'Introduction to the Book of Daniel',
      url: `${articlesRoot}/introduction-to-the-book-of-daniel`,
      source: 'KJVSB'
    }
  ],
  hos: [
    // 28
    {
      title: 'Introduction to the Book of Hosea',
      url: `${articlesRoot}/introduction-to-the-book-of-hosea`,
      source: 'KJVSB'
    }
  ],
  jol: [
    // 29
    {
      title: 'Introduction to the Book of Joel',
      url: `${articlesRoot}/introduction-to-the-book-of-joel`,
      source: 'KJVSB'
    }
  ],
  amo: [
    // 30
    {
      title: 'Introduction to the Book of Amos',
      url: `${articlesRoot}/introduction-to-the-book-of-amos`,
      source: 'KJVSB'
    }
  ],
  oba: [
    // 31
    {
      title: 'Introduction to the Book of Obadiah',
      url: `${articlesRoot}/introduction-to-the-book-of-obadiah`,
      source: 'KJVSB'
    }
  ],
  jon: [
    // 32
    {
      title: 'Introduction to the Book of jonah',
      url: `${articlesRoot}/introduction-to-the-book-of-jonah`,
      source: 'KJVSB'
    },
    {
      title: 'God’s Mercy',
      url: `${articlesRoot}/gods-mercy`,
      source: 'KJVSB',
      chapter: 4
    }
  ],
  mic: [
    // 33
    {
      title: 'Introduction to the Book of Micah',
      url: `${articlesRoot}/introduction-to-the-book-of-micah`,
      source: 'KJVSB'
    }
  ],
  nam: [
    // 34
    {
      title: 'Introduction to the Book of Nahum',
      url: `${articlesRoot}/introduction-to-the-book-of-nahum`,
      source: 'KJVSB'
    }
  ],
  hab: [
    // 35
    {
      title: 'Introduction to the Book of Habakkuk',
      url: `${articlesRoot}/introduction-to-the-book-of-habakkuk`,
      source: 'KJVSB'
    }
  ],
  zep: [
    // 36
    {
      title: 'Introduction to the Book of Zephaniah',
      url: `${articlesRoot}/introduction-to-the-book-of-zephaniah`,
      source: 'KJVSB'
    },
    {
      title: 'The Day of the Lord',
      url: `${articlesRoot}/day-lord`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  hag: [
    // 37
    {
      title: 'Introduction to the Book of Haggai',
      url: `${articlesRoot}/introduction-to-the-book-of-haggai`,
      source: 'KJVSB'
    }
  ],
  zec: [
    // 38
    {
      title: 'Introduction to the Book of Zechariah',
      url: `${articlesRoot}/introduction-to-the-book-of-zechariah`,
      source: 'KJVSB'
    }
  ],
  mal: [
    // 39
    {
      title: 'What Happened Between the Testaments?',
      url: `${articlesRoot}/what-happened-between-the-testaments`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Malachi',
      url: `${articlesRoot}/introduction-to-the-book-of-malachi`,
      source: 'KJVSB'
    }
  ],
  mat: [
    // 40
    {
      title: 'What Happened Between the Testaments?',
      url: `${articlesRoot}/what-happened-between-the-testaments`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Gospels and Acts',
      url: `${articlesRoot}/introduction-to-the-gospels-and-acts`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Matthew',
      url: `${articlesRoot}/introduction-to-the-book-of-matthew`,
      source: 'KJVSB'
    },
    {
      title: 'The Trinity',
      url: `${articlesRoot}/trinity`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  mrk: [
    // 41
    {
      title: 'Introduction to the Book of Mark',
      url: `${articlesRoot}/introduction-to-the-book-of-mark`,
      source: 'KJVSB'
    },
    {
      title: 'Christian Discipleship',
      url: `${articlesRoot}/christian-discipleship`,
      source: 'KJVSB',
      chapter: 8
    }
  ],
  luk: [
    // 42
    {
      title: 'Introduction to the Book of Luke',
      url: `${articlesRoot}/introduction-to-the-book-of-luke`,
      source: 'KJVSB'
    },
    {
      title: 'Angels',
      url: `${articlesRoot}/angels`,
      source: 'KJVSB',
      chapter: 9
    },
    {
      title: 'The Lord’s Supper',
      url: `${articlesRoot}/lords-supper`,
      source: 'KJVSB',
      chapter: 22
    }
  ],
  jhn: [
    // 43
    {
      title: 'Introduction to the Book of John',
      url: `${articlesRoot}/introduction-to-the-book-of-john`,
      source: 'KJVSB'
    },
    {
      title: 'Christ’s Incarnation',
      url: `${articlesRoot}/christs-incarnation`,
      source: 'KJVSB',
      chapter: 1
    },
    {
      title: 'Experiential Knowledge',
      url: `${articlesRoot}/experiential-knowledge`,
      source: 'KJVSB',
      chapter: 17
    }
  ],
  act: [
    // 44
    {
      title: 'Introduction to the Book of Acts',
      url: `${articlesRoot}/introduction-to-the-book-of-acts`,
      source: 'KJVSB'
    },
    {
      title: 'The Holy Spirit',
      url: `${articlesRoot}/holy-spirit`,
      source: 'KJVSB',
      chapter: 13
    }
  ],
  rom: [
    // 45
    {
      title: 'Introduction to the Epistles',
      url: `${articlesRoot}/introduction-to-the-epistles`,
      source: 'KJVSB'
    },
    {
      title: 'Introduction to the Book of Romans',
      url: `${articlesRoot}/introduction-to-the-book-of-romans`,
      source: 'KJVSB'
    },
    {
      title: 'Adoption',
      url: `${articlesRoot}/adoption`,
      source: 'KJVSB',
      chapter: 8
    },
    {
      title: 'Christ’s Satisfaction of God’s Justice',
      url: `${articlesRoot}/christs-satisfaction-gods-justice`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  '1co': [
    // 46
    {
      title: 'Introduction to the Book of 1 Corinthians',
      url: `${articlesRoot}/introduction-to-the-book-of-1-corinthians`,
      source: 'KJVSB'
    },
    {
      title: 'The Calling of God',
      url: `${articlesRoot}/calling-god`,
      source: 'KJVSB',
      chapter: 1
    },
    {
      title: 'Christ’s Resurrection and Ours',
      url: `${articlesRoot}/christs-resurrection-ours`,
      source: 'KJVSB',
      chapter: 15
    }
  ],
  '2co': [
    // 47
    {
      title: 'Introduction to the Book of 2 Corinthians',
      url: `${articlesRoot}/introduction-to-the-book-of-2-corinthians`,
      source: 'KJVSB'
    }
  ],
  gal: [
    // 48
    {
      title: 'Introduction to the Book of Galatians',
      url: `${articlesRoot}/introduction-to-the-book-of-galatians`,
      source: 'KJVSB'
    },
    {
      title: 'Justification by Faith Alone',
      url: `${articlesRoot}/justification-by-faith-alone`,
      source: 'KJVSB',
      chapter: 2
    }
  ],
  eph: [
    // 49
    {
      title: 'Introduction to the Book of Ephesians',
      url: `${articlesRoot}/introduction-to-the-book-of-ephesians`,
      source: 'KJVSB'
    },
    {
      title: 'Spiritual Warfare',
      url: `${articlesRoot}/spiritual-warfare`,
      source: 'KJVSB',
      chapter: 6
    }
  ],
  php: [
    // 50
    {
      title: 'Introduction to the Book of Philippians',
      url: `${articlesRoot}/introduction-to-the-book-of-philippians`,
      source: 'KJVSB'
    },
    {
      title: 'Christ’s States',
      url: `${articlesRoot}/christs-states`,
      source: 'KJVSB',
      chapter: 4
    }
  ],
  col: [
    // 51
    {
      title: 'Introduction to the Book of Colossians',
      url: `${articlesRoot}/introduction-to-the-book-of-colossians`,
      source: 'KJVSB'
    },
    {
      title: 'The Image of God',
      url: `${articlesRoot}/image-of-god`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  '1th': [
    // 52
    {
      title: 'Introduction to the Book of 1 Thessalonians',
      url: `${articlesRoot}/introduction-to-the-book-of-1-thessalonians`,
      source: 'KJVSB'
    }
  ],
  '2th': [
    // 53
    {
      title: 'Introduction to the Book of 2 Thessalonians',
      url: `${articlesRoot}/introduction-to-the-book-of-2-thessalonians`,
      source: 'KJVSB'
    },
    {
      title: 'Election',
      url: `${articlesRoot}/election`,
      source: 'KJVSB',
      chapter: 2
    }
  ],
  '1ti': [
    // 54
    {
      title: 'Introduction to the Book of 1 Timothy',
      url: `${articlesRoot}/introduction-to-the-book-of-1-timothy`,
      source: 'KJVSB'
    }
  ],
  '2ti': [
    // 55
    {
      title: 'Introduction to the Book of 2 Timothy',
      url: `${articlesRoot}/introduction-to-the-book-of-2-timothy`,
      source: 'KJVSB'
    }
  ],
  tit: [
    // 56
    {
      title: 'Introduction to the Book of Titus',
      url: `${articlesRoot}/introduction-to-the-book-of-titus`,
      source: 'KJVSB'
    },
    {
      title: 'Regeneration',
      url: `${articlesRoot}/regeneration`,
      source: 'KJVSB',
      chapter: 3
    }
  ],
  phm: [
    // 57
    {
      title: 'Introduction to the Book of Philemon',
      url: `${articlesRoot}/introduction-to-the-book-of-philemon`,
      source: 'KJVSB'
    },
    {
      title: 'Communion of the Saints',
      url: `${articlesRoot}/communion-saints`,
      source: 'KJVSB',
      chapter: 2
    }
  ],
  heb: [
    // 58
    {
      title: 'Introduction to the Book of Hebrews',
      url: `${articlesRoot}/introduction-to-the-book-of-hebrews`,
      source: 'KJVSB'
    },
    {
      title: 'The Offices of Christ',
      url: `${articlesRoot}/offices-of-christ`,
      source: 'KJVSB',
      chapter: 7
    }
  ],
  jas: [
    // 59
    {
      title: 'Introduction to the Book of James',
      url: `${articlesRoot}/introduction-to-the-book-of-james`,
      source: 'KJVSB'
    },
    {
      title: 'Christ’s Coming in Glory',
      url: `${articlesRoot}/christs-coming-glory`,
      source: 'KJVSB',
      chapter: 5
    }
  ],
  '1pe': [
    // 60
    {
      title: 'Introduction to the Book of 1 Peter',
      url: `${articlesRoot}/introduction-to-the-book-of-1-peter`,
      source: 'KJVSB'
    },
    {
      title: 'The Church',
      url: `${articlesRoot}/church`,
      source: 'KJVSB',
      chapter: 2
    }
  ],
  '2pe': [
    // 61
    {
      title: 'Introduction to the Book of 2 Peter',
      url: `${articlesRoot}/introduction-to-the-book-of-2-peter`,
      source: 'KJVSB'
    }
  ],
  '1jn': [
    // 62
    {
      title: 'Introduction to the Book of 1 John',
      url: `${articlesRoot}/introduction-to-the-book-of-1-john`,
      source: 'KJVSB'
    },
    {
      title: 'Assurance of Salvation',
      url: `${articlesRoot}/assurance-salvation`,
      source: 'KJVSB',
      chapter: 5
    }
  ],
  '2jn': [
    // 63
    {
      title: 'Introduction to the Book of 2 John',
      url: `${articlesRoot}/introduction-to-the-book-of-2-john`,
      source: 'KJVSB'
    }
  ],
  '3jn': [
    // 64
    {
      title: 'Introduction to the Book of 3 John',
      url: `${articlesRoot}/introduction-to-the-book-of-3-john`,
      source: 'KJVSB'
    },
    {
      title: 'World Missions',
      url: `${articlesRoot}/world-missions`,
      source: 'KJVSB',
      chapter: 1
    }
  ],
  jud: [
    // 65
    {
      title: 'Introduction to the Book of Jude',
      url: `${articlesRoot}/introduction-to-the-book-of-jude`,
      source: 'KJVSB'
    }
  ],
  rev: [
    // 66
    {
      title: 'Introduction to the Book of Revelation',
      url: `${articlesRoot}/introduction-to-the-book-of-revelation`,
      source: 'KJVSB'
    },
    {
      title: 'Hell',
      url: `${articlesRoot}/hell`,
      source: 'KJVSB',
      chapter: 14
    },
    {
      title: 'Heaven',
      url: `${articlesRoot}/heaven`,
      source: 'KJVSB',
      chapter: 21
    }
  ],
  none: [
    {
      url: `${articlesRoot}/welcome`,
      title: 'Welcome',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/alphabetical-list-of-the-books-of-the-bible`,
      title: 'Alphabetical List of the Books of the Bible',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/abbreviations`,
      title: 'Abbreviations',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/the-king-james-version-its-tradition-text-and-translation`,
      title: 'The King James Version: Its Tradition, Text, and Translation',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/editors-contact-and-sources`,
      title: 'Editors and Sources',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/only-true-god`,
      title: 'The Only True God',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-names`,
      title: 'God’s Names',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/trinity`,
      title: 'The Trinity',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-eternity`,
      title: 'God’s Eternity',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-immutability`,
      title: 'God’s Immutability',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-foreknowledge`,
      title: 'God’s Foreknowledge',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-wisdom`,
      title: 'God’s Wisdom',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-holiness-ours`,
      title: 'God’s Holiness and Ours',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-love-justice`,
      title: 'God’s Love and Justice',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-mercy`,
      title: 'God’s Mercy',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-faithfulness`,
      title: 'God’s Faithfulness',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/authority-inerrancy-scripture`,
      title: 'The Authority and Inerrancy of Scripture',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/creation-gods-glory`,
      title: 'Creation and God’s Glory',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/angels`,
      title: 'Angels',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/image-of-god`,
      title: 'The Image of God',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/soul`,
      title: 'The Soul',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/satan`,
      title: 'Satan',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/fall-of-man`,
      title: 'The Fall of Man',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/total-depravity`,
      title: 'Total Depravity',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/human-responsibility`,
      title: 'Human Responsibility',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/this-evil-world`,
      title: 'This Evil World',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-covenants`,
      title: 'God’s Covenants',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/angel-lord`,
      title: 'The Angel of the Lord',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/son-of-david`,
      title: 'The Son of David',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/offices-of-christ`,
      title: 'The Offices of Christ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christs-incarnation`,
      title: 'Christ’s Incarnation',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christs-states`,
      title: 'Christ’s States',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christs-satisfaction-gods-justice`,
      title: 'Christ’s Satisfaction of God’s Justice',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christs-resurrection-ours`,
      title: 'Christ’s Resurrection and Ours',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christs-kingdom`,
      title: 'Christ’s Kingdom',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/election`,
      title: 'Election',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/uses-of-the-moral-law`,
      title: 'The Uses of the Moral Law',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/holy-spirit`,
      title: 'The Holy Spirit',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/calling-god`,
      title: 'The Calling of God',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/regeneration`,
      title: 'Regeneration',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/faith-in-god`,
      title: 'Faith in God',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/justification-by-faith-alone`,
      title: 'Justification by Faith Alone',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/adoption`,
      title: 'Adoption',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/repentance`,
      title: 'Repentance',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/experiential-knowledge`,
      title: 'Experiential Knowledge',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/obedience-to-gods-will`,
      title: 'Obedience to God’s Will',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/assurance-salvation`,
      title: 'Assurance of Salvation',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christian-discipleship`,
      title: 'Christian Discipleship',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/spiritual-warfare`,
      title: 'Spiritual Warfare',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/perseverance-of-the-saints`,
      title: 'The Perseverance of the Saints',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/gods-dwelling-with-man`,
      title: 'God’s Dwelling with Man',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/church`,
      title: 'The Church',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/means-of-grace`,
      title: 'The Means of Grace',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/worship-by-the-word`,
      title: 'Worship by the Word',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/lords-supper`,
      title: 'The Lord’s Supper',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/communion-saints`,
      title: 'Communion of the Saints',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/world-missions`,
      title: 'World Missions',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/canaan-inheritance`,
      title: 'Canaan and the Inheritance',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/day-lord`,
      title: 'The Day of the Lord',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/christs-coming-glory`,
      title: 'Christ’s Coming in Glory',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/heaven`,
      title: 'Heaven',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/hell`,
      title: 'Hell',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/what-happened-between-the-testaments`,
      title: 'What Happened Between the Testaments?',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/coming-to-christ`,
      title: 'Coming to Christ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/united-with-christ`,
      title: 'United with Christ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/experiencing-justification-and-adoption`,
      title: 'Experiencing Justification and Adoption',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/growing-in-sanctification`,
      title: 'Growing in Sanctification',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/assured-and-persevering`,
      title: 'Assured and Persevering',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/reading-the-scriptures`,
      title: 'Reading the Scriptures',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/why-and-how-we-pray`,
      title: 'Why and How We Pray',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/worship-and-the-means-of-grace`,
      title: 'Worship and the Means of Grace',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/fellowship-with-believers`,
      title: 'Fellowship with Believers',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/how-we-regard-ourselves`,
      title: 'How We Regard Ourselves',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/love-to-god`,
      title: 'Love to God',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/the-fear-of-god`,
      title: 'The Fear of God',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/living-by-the-ten-commandments`,
      title: 'Living by the Ten Commandments',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/godly-contentment`,
      title: 'Godly Contentment',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/self-denial`,
      title: 'Self-Denial',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/humility`,
      title: 'Humility',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/how-we-kill-pride`,
      title: 'How We Kill Pride',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/coping-with-criticism`,
      title: 'Coping with Criticism',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/enduring-affliction`,
      title: 'Enduring Affliction',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/spiritual-desertion`,
      title: 'Spiritual Desertion',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/fleeing-worldliness`,
      title: 'Fleeing Worldliness',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/fighting-against-backsliding`,
      title: 'Fighting against Backsliding',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/family-worship`,
      title: 'Family Worship',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/being-a-christ-like-husband`,
      title: 'Being a Christ-like Husband',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/being-a-godly-wife`,
      title: 'Being a Godly Wife',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/showing-hospitality`,
      title: 'Showing Hospitality',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/raising-children-in-the-lord`,
      title: 'Raising Children in the Lord',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/being-a-christian-grandparent`,
      title: 'Being a Christian Grandparent',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/honoring-your-parents`,
      title: 'Honoring Your Parents',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/serving-god-at-work`,
      title: 'Serving God at Work',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/using-leisure-time-well`,
      title: 'Using Leisure Time Well',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/witnessing-for-christ`,
      title: 'Witnessing for Christ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/defending-our-faith`,
      title: 'Defending Our Faith',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/facing-sickness-and-death`,
      title: 'Facing Sickness and Death',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/living-positively`,
      title: 'Living Positively',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/living-for-gods-glory`,
      title: 'Living for God’s Glory',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-1st-century`,
      title: 'First Century: Apostolic Foundations',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-2nd-century`,
      title: 'Second Century: The Church of Martyrs and Confessors',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-3rd-century`,
      title: 'The Third Century: Persecution and Heresy; Origen and Tertullian',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-4th-century`,
      title: 'The Fourth Century: The Beginnings of the Christian Empire',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-5th-century`,
      title: 'The Fifth Century: The City of God and the City of Man',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-6th-century`,
      title:
        'The Sixth Century: Justinian, Benedict, and the Conversion of the Scots',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-7th-century`,
      title: 'The Seventh Century: Gregory the Great and the Rise of Islam',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-8th-century`,
      title: 'The Eighth Century: The Iconoclastic Controversy',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-9th-century`,
      title:
        'The Ninth Century: Struggle for Power in the Church; Ratramnus and Gottschalk',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-10th-century`,
      title: 'The Tenth Century: “The Dark Ages”',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-11th-century`,
      title: 'The Eleventh Century: The Great Schism; Anselm of Canterbury ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-12th-century`,
      title:
        'The Twelfth Century: The Crusades, Abelard, Lombard, and the Waldenses ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-13th-century`,
      title: 'The Thirteenth Century: Francis of Assisi and Thomas Aquinas',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-14th-century`,
      title:
        'The Fourteenth Century: The Church’s Babylonian Captivity and John Wycliffe ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-15th-century`,
      title:
        'The Fifteenth Century: The Renaissance, Huss, Savonarola, and Groote',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-16th-century`,
      title: 'The Sixteenth Century: Luther, Calvin, and the Reformation',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-17th-century`,
      title: 'The Seventeenth Century: Reforming the Church in England',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-18th-century`,
      title: 'The Eighteenth Century: The Great Awakening ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-19th-century`,
      title:
        'The Nineteenth Century: Beginnings of Modern Theology and Kingdom Builders ',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/history-20th-century`,
      title: 'The Twentieth Century: The Age of Paradoxes',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/apostles-creed`,
      title: 'Apostles’ Creed',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/nicene-creed`,
      title: 'Nicene Creed',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/athanasian-creed`,
      title: 'Athanasian Creed',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/belgic-confession`,
      title: 'Belgic Confession',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/heidelberg-catechism`,
      title: 'Heidelberg Catechism',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/canons-of-dort`,
      title: 'Canons of Dort',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/westminster-confession-of-faith`,
      title: 'Westminster Confession',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/westminster-shorter-catechism`,
      title: 'Westminster Shorter Catechism',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/westminster-larger-catechism`,
      title: 'Westminster Larger Catechism',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/read-the-bible-in-a-year`,
      title: 'Read the Bible in a Year',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/money-weights-and-measures`,
      title: 'Money, Weights, and Measures',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance`,
      title: 'Concordance',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/a`,
      title: 'Concordance: A',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/b`,
      title: 'Concordance: B',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/c`,
      title: 'Concordance: C',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/d`,
      title: 'Concordance: D',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/e`,
      title: 'Concordance: E',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/f`,
      title: 'Concordance: F',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/g`,
      title: 'Concordance: G',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/h`,
      title: 'Concordance: H',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/i`,
      title: 'Concordance: I',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/j`,
      title: 'Concordance: J',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/k`,
      title: 'Concordance: K',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/l`,
      title: 'Concordance: L',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/m`,
      title: 'Concordance: M',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/n`,
      title: 'Concordance: N',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/o`,
      title: 'Concordance: O',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/p`,
      title: 'Concordance: P',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/q`,
      title: 'Concordance: Q',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/r`,
      title: 'Concordance: R',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/s`,
      title: 'Concordance: S',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/t`,
      title: 'Concordance: T',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/u`,
      title: 'Concordance: U',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/v`,
      title: 'Concordance: V',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/w`,
      title: 'Concordance: W',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/y`,
      title: 'Concordance: Y',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/concordance/z`,
      title: 'Concordance: Z',
      source: 'KJVSB'
    },
    {
      url: `${articlesRoot}/acknowledgements`,
      title: 'Acknowledgements',
      source: 'KJVSB'
    }
  ]
};

// The same data, but listed simply by URL, for easy lookup internally.
const byUrl = {};
for (const book in byLocation) {
  for (const idx in byLocation[book]) {
    const url = byLocation[book][idx].url;
    byUrl[url] = byLocation[book][idx];
  }
}

// Supply both means of organization as interfaces to the outline.
module.exports = { byLocation, byUrl };
