'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/16/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

// Module-level constants.
const LOGIN = '/user/sign-in';
const PROFILE = '/user/profile';


// Define an Angular controller.
function PasswordResetController ($location, $scope, $timeout, user, messages) {
  $scope.resetPassword = function () {
    const reset = {
      password: $scope.user.password,
      confirm: $scope.user.confirm,
      token: $location.search().token,
      username: $location.search().username
    };

    user.reset(reset, (status, data) => {
      if (status === 200 && !!data.succeeded) {
        messages.resetPassword = 'Password updated successfully!';

        // Having succeeded, log in with the new password.
        const newLogin = {
          username: $location.search().username,
          password: $scope.user.password
        };

        user.login(newLogin, (status/*, data*/) => {
          if (status === 200) {
            // Only display the message briefly, then go to the user's profile.
            $timeout(() => { $location.path(PROFILE); }, 2500);
          }
          else {
            // FIXME: presumably there should be something here!!!
          }
        });

      }
      else {
        // Add an error message to the messages service.
        const reason = angular.isDefined(data.reason) ? ` ${data.reason}` : '';
        const message = angular.isDefined(data.message) ? ` ${data.message}` : '';
        messages.resetPassword = `There was a problem resetting your password.${reason}${message}`;
        $location.path(LOGIN);
      }
    });
  };
}

// Add the controller to the users module.
angular.module('hbUsers')
  .controller('PasswordResetController', [
    // Inject dependencies. Angular:
    '$location', '$scope', '$timeout',
    // Project (`hb`):
    'hbUser', 'hbMessages',
    // Supply the controller.
    PasswordResetController
  ]);
