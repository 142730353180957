'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/16/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

// Define an Angular controller.
function UsersController($scope, hbSession, hbMessages) {
  // Add scope objects for managing the user's information, so it can be
  // displayed when relevant and referenced by the view.
  const session = hbSession.get();
  if (session && session.user) {
    $scope.user = {
      username: session.user.name,
      confirmed: session.user.confirmed
    };
  }

  // Bind the messages singleton to the view.
  $scope.messages = hbMessages;
}

// Define an Angular module and bind the controller.
angular.module('hbUsers', [])
  .controller('UsersController', [
    // Inject dependencies. Angular:
    '$scope',
    // First-party (hb):
    'hbSession', 'hbMessages',
    // Supply the controller.
    UsersController
  ]);

// The Browserify package includes all JS modules that comprise the AngularJS
// `hbUsers` module.
require('./confirmation-controller');
require('./login-controller');
require('./password-reset-controller');
require('./profile-controller');
require('./resources-controller');
require('./signup-controller');
