'use strict';
/**
  Author: Chris Krycho
  Contact: chris@krycho.com
  Date: 1/16/15
  License: All rights reserved.
  Copyright: 2015 Puritan Reformed Theological Seminary
 */

// Define an Angular controller.
function LoginController ($location, $scope, $timeout, messages, session, user) {
  // Define an object against which the form can bind.
  $scope.user = { username: '', password: '' };

  // Bind the local scope messages to the hbMessages singleton data.
  $scope.messages = messages;

  // Process a form requesting user login.
  $scope.login = (form) => {
    const clearForm = () => {
      $scope.user.password = undefined;
    };

    const handler = user.makeLoginHandler(
      200, session, $scope, $location, form, messages, clearForm);

    user.login($scope.user, handler);
  };

  // Process a request for a new password.
  $scope.forgot = () => {
    if (angular.isDefined($scope.user.username)) {
      const forgot = { username: $scope.user.username };
      user.forgot(forgot, (status, data) => {
        if (status === 200 && !!data.sent) {
          messages.forgotPassword =
            'Thanks! You should receive an email shortly with instructions ' +
            'for resetting your email.';

          // Only display the success message for a few seconds.
          $timeout(() => { messages.passwordUpdate = ''; }, 10000);
        }
        else {
          messages.forgotPassword =
            'Sorry, there was a problem resetting your password. Please try ' +
            'again. If the problem presists, please send an email describing ' +
            'the problem and including your current username to ' +
            '<a href="mailto:holybible@heritagebooks.org">holybible@heritagebooks.org</a>.';
        }
      });
    }
    else {
      messages.forgotPassword =
        'You must supply a username (email address) to reset your password.';
    }
  };

}

// Add the controller to the users module.
angular.module('hbUsers').controller('LoginController', [
  // Specify dependencies. Angular:
  '$location', '$scope', '$timeout',
  // Project (`hb`):
  'hbMessages', 'hbSession', 'hbUser',
  // Supply the controller.
  LoginController
]);
