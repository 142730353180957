// Define the Bible outline for navigation. NOTE: these counts are valid *only*
// for the English Bible. If at some point we add the Hebrew, we will have to
// handle this differently.
module.exports = {
  'gen': {id: 'gen', order: 0, abbr: 'Gen', title: 'Genesis', numChapters: 50, section: 'Old Testament'},
  'exo': {id: 'exo', order: 1, abbr: 'Exo', title: 'Exodus', numChapters: 40, section: 'Old Testament'},
  'lev': {id: 'lev', order: 2, abbr: 'Lev', title: 'Leviticus', numChapters: 27, section: 'Old Testament'},
  'num': {id: 'num', order: 3, abbr: 'Num', title: 'Numbers', numChapters: 36, section: 'Old Testament'},
  'deu': {id: 'deu', order: 4, abbr: 'Deu', title: 'Deuteronomy', numChapters: 34, section: 'Old Testament'},
  'jos': {id: 'jos', order: 5, abbr: 'Jos', title: 'Joshua', numChapters: 24, section: 'Old Testament'},
  'jdg': {id: 'jdg', order: 6, abbr: 'Jdg', title: 'Judges', numChapters: 21, section: 'Old Testament'},
  'rth': {id: 'rth', order: 7, abbr: 'Rth', title: 'Ruth', numChapters: 4, section: 'Old Testament'},
  '1sa': {id: '1sa', order: 8, abbr: '1Sa', title: '1 Samuel', numChapters: 31, section: 'Old Testament'},
  '2sa': {id: '2sa', order: 9, abbr: '2Sa', title: '2 Samuel', numChapters: 24, section: 'Old Testament'},
  '1ki': {id: '1ki', order: 10, abbr: '1Ki', title: '1 Kings', numChapters: 22, section: 'Old Testament'},
  '2ki': {id: '2ki', order: 11, abbr: '2Ki', title: '2 Kings', numChapters: 25, section: 'Old Testament'},
  '1ch': {id: '1ch', order: 12, abbr: '1Ch', title: '1 Chronicles', numChapters: 29, section: 'Old Testament'},
  '2ch': {id: '2ch', order: 13, abbr: '2Ch', title: '2 Chronicles', numChapters: 36, section: 'Old Testament'},
  'ezr': {id: 'ezr', order: 14, abbr: 'Ezr', title: 'Ezra', numChapters: 10, section: 'Old Testament'},
  'neh': {id: 'neh', order: 15, abbr: 'Neh', title: 'Nehemiah', numChapters: 13, section: 'Old Testament'},
  'est': {id: 'est', order: 16, abbr: 'Est', title: 'Esther', numChapters: 10, section: 'Old Testament'},
  'job': {id: 'job', order: 17, abbr: 'Job', title: 'Job', numChapters: 42, section: 'Old Testament'},
  'psa': {id: 'psa', order: 18, abbr: 'Psa', title: 'Psalms', numChapters: 150, section: 'Old Testament'},
  'pro': {id: 'pro', order: 19, abbr: 'Pro', title: 'Proverbs', numChapters: 31, section: 'Old Testament'},
  'ecc': {id: 'ecc', order: 20, abbr: 'Ecc', title: 'Ecclesiastes', numChapters: 12, section: 'Old Testament'},
  'sng': {id: 'sng', order: 21, abbr: 'Sng', title: 'Song of Songs', numChapters: 8, section: 'Old Testament'},
  'isa': {id: 'isa', order: 22, abbr: 'Isa', title: 'Isaiah', numChapters: 66, section: 'Old Testament'},
  'jer': {id: 'jer', order: 23, abbr: 'Jer', title: 'Jeremiah', numChapters: 52, section: 'Old Testament'},
  'lam': {id: 'lam', order: 24, abbr: 'Lam', title: 'Lamentations', numChapters: 5, section: 'Old Testament'},
  'ezk': {id: 'ezk', order: 25, abbr: 'Ezk', title: 'Ezekiel', numChapters: 48, section: 'Old Testament'},
  'dan': {id: 'dan', order: 26, abbr: 'Dan', title: 'Daniel', numChapters: 12, section: 'Old Testament'},
  'hos': {id: 'hos', order: 27, abbr: 'Hos', title: 'Hosea', numChapters: 14, section: 'Old Testament'},
  'jol': {id: 'jol', order: 28, abbr: 'Jol', title: 'Joel', numChapters: 3, section: 'Old Testament'},
  'amo': {id: 'amo', order: 29, abbr: 'Amo', title: 'Amos', numChapters: 9, section: 'Old Testament'},
  'oba': {id: 'oba', order: 30, abbr: 'Oba', title: 'Obadiah', numChapters: 1, section: 'Old Testament'},
  'jon': {id: 'jon', order: 31, abbr: 'Jon', title: 'Jonah', numChapters: 4, section: 'Old Testament'},
  'mic': {id: 'mic', order: 32, abbr: 'Mic', title: 'Micah', numChapters: 7, section: 'Old Testament'},
  'nam': {id: 'nam', order: 33, abbr: 'Nam', title: 'Nahum', numChapters: 3, section: 'Old Testament'},
  'hab': {id: 'hab', order: 34, abbr: 'Hab', title: 'Habakkuk', numChapters: 3, section: 'Old Testament'},
  'zep': {id: 'zep', order: 35, abbr: 'Zep', title: 'Zephaniah', numChapters: 3, section: 'Old Testament'},
  'hag': {id: 'hag', order: 36, abbr: 'Hag', title: 'Haggai', numChapters: 2, section: 'Old Testament'},
  'zec': {id: 'zec', order: 37, abbr: 'Zec', title: 'Zechariah', numChapters: 14, section: 'Old Testament'},
  'mal': {id: 'mal', order: 38, abbr: 'Mal', title: 'Malachi', numChapters: 4, section: 'Old Testament'},

  'mat': {id: 'mat', order: 39, abbr: 'Mat', title: 'Matthew', numChapters: 28, section: 'New Testament'},
  'mrk': {id: 'mrk', order: 40, abbr: 'Mrk', title: 'Mark', numChapters: 16, section: 'New Testament'},
  'luk': {id: 'luk', order: 41, abbr: 'Luk', title: 'Luke', numChapters: 24, section: 'New Testament'},
  'jhn': {id: 'jhn', order: 42, abbr: 'Jhn', title: 'John', numChapters: 21, section: 'New Testament'},
  'act': {id: 'act', order: 43, abbr: 'Act', title: 'Acts', numChapters: 28, section: 'New Testament'},
  'rom': {id: 'rom', order: 44, abbr: 'Rom', title: 'Romans', numChapters: 16, section: 'New Testament'},
  '1co': {id: '1co', order: 45, abbr: '1Co', title: '1 Corinthians', numChapters: 16, section: 'New Testament'},
  '2co': {id: '2co', order: 46, abbr: '2Co', title: '2 Corinthians', numChapters: 13, section: 'New Testament'},
  'gal': {id: 'gal', order: 47, abbr: 'Gal', title: 'Galatians', numChapters: 6, section: 'New Testament'},
  'eph': {id: 'eph', order: 48, abbr: 'Eph', title: 'Ephesians', numChapters: 6, section: 'New Testament'},
  'php': {id: 'php', order: 49, abbr: 'Php', title: 'Philippians', numChapters: 4, section: 'New Testament'},
  'col': {id: 'col', order: 50, abbr: 'Col', title: 'Colossians', numChapters: 4, section: 'New Testament'},
  '1th': {id: '1th', order: 51, abbr: '1Th', title: '1 Thessalonians', numChapters: 5, section: 'New Testament'},
  '2th': {id: '2th', order: 52, abbr: '2Th', title: '2 Thessalonians', numChapters: 3, section: 'New Testament'},
  '1ti': {id: '1ti', order: 53, abbr: '1Ti', title: '1 Timothy', numChapters: 6, section: 'New Testament'},
  '2ti': {id: '2ti', order: 54, abbr: '2Ti', title: '2 Timothy', numChapters: 4, section: 'New Testament'},
  'tit': {id: 'tit', order: 55, abbr: 'Tit', title: 'Titus', numChapters: 3, section: 'New Testament'},
  'phm': {id: 'phm', order: 56, abbr: 'Phm', title: 'Philemon', numChapters: 1, section: 'New Testament'},
  'heb': {id: 'heb', order: 57, abbr: 'Heb', title: 'Hebrews', numChapters: 13, section: 'New Testament'},
  'jas': {id: 'jas', order: 58, abbr: 'Jas', title: 'James', numChapters: 5, section: 'New Testament'},
  '1pe': {id: '1pe', order: 59, abbr: '1Pe', title: '1 Peter', numChapters: 5, section: 'New Testament'},
  '2pe': {id: '2pe', order: 60, abbr: '2Pe', title: '2 Peter', numChapters: 3, section: 'New Testament'},
  '1jn': {id: '1jn', order: 61, abbr: '1Jn', title: '1 John', numChapters: 5, section: 'New Testament'},
  '2jn': {id: '2jn', order: 62, abbr: '2Jn', title: '2 John', numChapters: 1, section: 'New Testament'},
  '3jn': {id: '3jn', order: 63, abbr: '3Jn', title: '3 John', numChapters: 1, section: 'New Testament'},
  'jud': {id: 'jud', order: 64, abbr: 'Jud', title: 'Jude', numChapters: 1, section: 'New Testament'},
  'rev': {id: 'rev', order: 65, abbr: 'Rev', title: 'Revelation', numChapters: 22, section: 'New Testament'}
};
