'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/8/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

// Define requirements for Browserify.
require('../app-controller');

// Module constants.
const API_ROOT = '/p';


// A note to whoever maintains this: I apologize. I did not yet have a good
// handle on the way to handle routing and views in Angular when I wrote it and
// do not have the budget allocated to be able to refactor all the relevant
// pieces at present.
function PageCtrl($http, $scope, $route, $routeParams) {
  $scope.content = '';

  // Load the requested data from the back end.
  const url = API_ROOT + '/' + $routeParams.page;
  $http.get(url)
    .then(data => { $scope.content = data.content; })
    .catch((data, status, headers/*, config*/) => {
      // On initial page load, the route must be reloaded to trigger API access.
      if (status === void 0) { $route.reload(); }

      $scope.$log.error(
        `An error occurred after requesting ${url}. Status: ${status} | Headers: ${headers}`
      );
    });
}

// Define a controller for page views.
angular.module('app').controller('PageController', [
  '$http', '$scope', '$route', '$routeParams',
  PageCtrl
]);
