'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/9/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

function usesvg() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { useid: '@' },
    templateNamespace: 'svg',
    template: '<use xlink:href=""/>',
    link(scope, use, attrs) {
      use.attr('xlink:href', '#' + attrs.useid);
    }
  };
}

// Define a transclusion for SVG <use> elements
angular.module('app').directive('usesvg', [usesvg]);
