'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/9/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

function ResourcesController ($scope, $timeout, sbAccess, session) {
  $scope.access = [];

  const sessionData = session.get();
  if (sessionData && sessionData.access) { $scope.access = sessionData.access; }

  // Add a scope object for managing the user's redemption request.
  $scope.sb = { key: null };

  $scope.hasSbAccess = function() {
    return $scope.access.indexOf('kjvsb') !== -1;
  };

  // Handle study bible key redemption requests.
  $scope.redeem = function (form) {
    const key = $scope.sb.key.toUpperCase();

    sbAccess.redeem(key, (status, data) => {
      // Handle errors.
      let message;
      if (status === 200) {
        $scope.access.push('kjvsb');
        session.set({ access: $scope.access });
      }
      else {
        if (status === 409 && !!data.conflict) { message = data.reason; }
        else {
          message =
            'There was a problem redeeming your code. Please try ' +
            'again. If this happens again, please send an email to ' +
            '<a href="mailto:holybible@heritagebooks.org">holybible@heritagebooks.org</a>. ' +
            'We apologize for the inconvenience!';
        }

        $scope.messages.keyError = message;

        form.$setPristine();
        form.$setSubmitted();

        // Only display the error message for a few seconds.
        $timeout(() => { $scope.messages.keyError = ''; }, 5000);
      }
    });
  };
}


// Add the controller to the users module.
angular.module('hbUsers').controller('ResourcesController', [
  // Inject dependencies. Angular:
  '$scope', '$timeout',
  // Project (`hb`):
  'hbSbAccess', 'hbSession',
  // Supply the controller.
  ResourcesController
]);
