'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 4/17/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */


/**
 * Constructor for the `text-notes` directive.
 *
 * @constructor
 */
function HbTextNotes($compile, $timeout, auth) {

  function link(scope/*, el, attrs*/) {
    // Don't do anything at all if the user isn't authorized for the KJVSB.
    if (!auth.isAuthorized('kjvsb')) { return; }

    // Don't do anything if there aren't any notes.
    if (angular.isUndefined(scope.textNotes)) { return; }

    // Define the note-rendering behavior.
    function renderNotes() {
      scope.textNotes.forEach((note, n) => {
        // Create a container (which will handle scoping).
        const div = document.createElement('div');
        div.setAttribute('class', 'text-note');
        div.setAttribute('data-hb-note-popover', '');
        div.setAttribute('data-note-reference', n);
        div.setAttribute('data-note-href', note.id);

        // Create a link to the note.
        const a = document.createElement('a');
        a.setAttribute('href', '');  // Makes the <a> click-able w/o CSS
        a.setAttribute('data-ng-click', 'toggleNote()');
        a.setAttribute('data-hb-note-link', '');

        let text;
        switch (note.type) {
        case 'inline':
          text = '†';
          a.setAttribute('class', 'note-link');
          break;
        case 'thought':
          text = 'Thoughts for Personal/Family Worship';
          a.setAttribute('class', 'note-link long-note-link');
          break;
        case 'head':
          text = 'Introduction to ' + note.title;
          a.setAttribute('class', 'note-link long-note-link');
          break;
        default:
          text = '';
          break;
        }

        a.appendChild(document.createTextNode(text));
        div.appendChild(a);

        // Set up the Angular bindings.
        $compile(div)(scope);

        // Insert the link element after the verse number.
        const insertAtID = note.ref;
        let targetEl = document.getElementById(insertAtID);
        let insertBefore;
        if (angular.element(targetEl).hasClass('chnum')) {
          insertBefore = targetEl.nextSibling.firstChild.nextSibling;
          targetEl = targetEl.nextSibling;
        }
        else {
          insertBefore = targetEl.firstChild.nextSibling;
        }

        targetEl.insertBefore(div, insertBefore);
      });
    }

    // We use `$timeout` to ensure that the digest cycle has finished and thus
    // that the page is laid out before updating it.
    $timeout(renderNotes);
  }

  return { link, restrict: 'A' };
}


// Construct the directive and bind it to the `hbBible` module.
angular.module('hbBible').directive('hbTextNotes', [
  // Inject dependencies. AngularJS:
  '$compile', '$timeout',
  // First-party (hb):
  'hbAuth',
  // Supply the constructor
  HbTextNotes
]);
