'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/8/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

// Define requirements for Browserify.
require('../app-controller');

/**
 * Search for a key in an object for a given value at a given attribute.
 * @param {Object} obj The object to search.
 * @param {string} attr
 * @param {vary} value
 * @returns {string}
 */
function keyFromObjByAttr(obj, attr, value) {
  for (const key in obj) {
    if (obj[key][attr] === value) { return key; }
  }
}


function SettingsController($scope, $rootScope, $timeout) {
  const READING_SETTINGS_EVENT = 'hbReadingSettingsChanged';
  const READING_SETTINGS_POPUP = 'hbReadingPopupToggled';

  function readingSettingIsPopup(setting) {
    return setting.type === 'choosable' || setting.type === 'iterable';
  }

  // Handle global popup value.
  $scope.popupsInactive = true;
  function managePopupContainer() {
    // Check whether any of the settings are active.
    for (const setting in $scope.readingSettings) {
      if (readingSettingIsPopup($scope.readingSettings[setting])) {
        if ($scope.readingSettings[setting].active) {
          $scope.popupsInactive = false;
          return;
        }
      }
    }

    // If we made it this far, none of the settings were active. Delay setting
    // the class because of a quirky interplay between the container and child
    // elements in terms of how the animations render.
    $timeout(() => { $scope.popupsInactive = true; }, 501);
  }

  $scope.$on(READING_SETTINGS_POPUP, managePopupContainer);

  /* Turn a settings button on or off, with corresponding changes for
   * associated popups. */
  $scope.toggleSetting = function toggleSetting(setting) {
    setting.active = !setting.active;
    if (readingSettingIsPopup(setting)) {
      for (const otherSetting in $scope.readingSettings) {
        if ($scope.readingSettings[otherSetting] !== setting &&
            $scope.readingSettings[otherSetting].popup) {

          $scope.readingSettings[otherSetting].active = false;
        }
      }

      $scope.$broadcast(READING_SETTINGS_POPUP);
    }
    else {
      $rootScope.$broadcast(READING_SETTINGS_EVENT);
    }
  };

  /* Iterate over settings options by setting order. */
  $scope.iterateOption = function iterateOption(setting, direction) {
    const currentOption = setting.currentOption;
    const maxPosition = setting.numOptions - 1;
    const minPosition = 0;

    const currentPosition = setting.options[currentOption].order;
    let newPosition = currentPosition;

    // Prevent the options from going out of bounds.
    if (direction === 'upward') {
      if (currentPosition !== maxPosition) {
        newPosition = currentPosition + 1;
      }
    }
    else if (direction === 'downward') {
      if (currentPosition !== minPosition) {
        newPosition = currentPosition - 1;
      }
    }
    else {
      const err = `Invalid direction of iteration ${direction}  for SettingsController::iterateOption.`;
      throw new Error(err);
    }

    // Update the currently selected option if it should change.
    if (newPosition !== currentPosition) {
      const newOption = keyFromObjByAttr(setting.options, 'order', newPosition);
      $scope.readingSettings[setting.id].options[currentOption].active = false;
      $scope.readingSettings[setting.id].options[newOption].active = true;
      $scope.readingSettings[setting.id].currentOption = newOption;

      $rootScope.$broadcast(READING_SETTINGS_EVENT);
    }
  };

  /* For options where there is a selection. */
  $scope.chooseOption = function (setting, option) {
    $scope.readingSettings[setting.id].currentOption = option.id;
  };
}

// Define a controller for the Settings panel.
angular.module('app').controller('SettingsController', [
  // Inject dependencies. AngularJS:
  '$scope', '$rootScope', '$timeout',
  // Supply the constructor.
  SettingsController
]);
