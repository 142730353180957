'use strict';
/**
  Author: Chris Krycho
  Contact: chris@krycho.com
  Date: 12/6/14
  License: All rights reserved.
  Copyright: 2014 Puritan Reformed Theological Seminary

  Define a controller for the Bible navigation field and popups.
 */

// Define requirements for Browserify.
require('../app-controller');
const config = require('../../app-config');

function BibleNavController($scope, $timeout, $rootElement, $location, locationMeta) {
  /* Controller setup: define data and functions */
  // Data structures with controller state.
  const touch = $rootElement.hasClass('touch');
  $scope.menu = {
    outline: $scope.outline,
    current: { book: '', numChapters: '' },
    sections: ['Old Testament', 'New Testament'],
    active: false,
    textEditable: !touch
  };

  $scope.searchField = {
    focused: false,
    text: '',
    clear() { this.text = ''; }
  };

  // TODO: move this. It does *not* belong here. (Part of splitting up
  // navigation controls!)
  $scope.sitenav = {
    active: false,
    toggleActive(active) {
      if (angular.isDefined(active)) { this.active = active; }
      else { this.active = !this.active; }
    }
  };

  // Build a request like `<book ID>.<chapter>.verse`, e.g. `isa.61.1`
  $scope.request = {
    book: '', chapter: '', verse: '',
    valid() {
      const validBook = $scope.menu.outline.hasOwnProperty(this.book);
      const numChapters = $scope.menu.outline[this.book].numChapters;
      const validChapter = this.chapter > 0 && this.chapter <= numChapters;
      return (validBook && validChapter);
    }
  };

  // Functions for modifying state.
  /**
   * Add a function to biblenav to update all relevant state when
   * toggling the menu visibility.
   *
   * @param {bool} [active] Optionally specify the state to which to set
   *     the toggle.
   *
   * @TODO: We use `$timeout` here for what is essentially a visual fix,
   *     because I haven't yet implemented this as a directive. See issue
   *     [#25][25] for further details.
   *
   * [25]: https://github.com/chriskrycho/holybible.com/issues/25
   */
  function toggleActive(active) {
    const previouslyActive = $scope.menu.active;
    if (angular.isDefined(active)) { $scope.menu.active = active; }
    else { $scope.menu.active = !$scope.menu.active; }

    if (previouslyActive) {
      $timeout(() => { $scope.cancelBookRequest(); }, 500);
    }
  }

  /**
   * Request a book.
   *
   * If the book is a valid book, this has three results:
   *
   *  1. It sets the biblenav current state to 'chapters', so that the nav
   *     may display a list of chapters instead of books.
   *  2. It sets the biblenav object's `chapters` property to the number
   *     of chapters in the requested book.
   *  3. It sets the `book` property of the `request` object to the book.
   *
   * @param bookId
   */
  function requestBook(bookId) {
    if (bookId in $scope.menu.outline) {
      $scope.menu.current.book = bookId;
      $scope.menu.current.numChapters = $scope.menu.outline[bookId].numChapters;
      $scope.request.book = bookId;
    }
  }

  /**
   * Request a specific chapter from the currently selected book.
   * @param {int} chapter
   */
  function requestChapter(chapter) {
    const currentBook = $scope.menu.current.book;
    if (currentBook !== null) {
      const maxChapters = $scope.menu.current.numChapters;

      if (chapter > 0 && parseInt(chapter) <= maxChapters) {
        $scope.request.chapter = chapter.toString();
        $scope.request.verse = '1';
      }
    }

    if ($scope.request.valid()) {
      const url = buildUrlFromComponents($scope.request);
      $location.path(url);
    }
  }

  /**
   * Cancel a request: reset the `biblenav.current` and `request` objects.
   */
  function cancelBookRequest() {
    $scope.menu.current.book = '';
    $scope.menu.current.numChapters = '';
    $scope.request.book = '';
  }

  // Whenever the path is updated, if the path is a Bible URL, update the
  // menu and search bar.
  // TODO: replace with $scope.$on($locationChangeSuccess).
  $scope.$watch(
    // Get the watched value: the URL.
    () => $location.path(),
    // When the value changes, update local data if is a Bible URL.
    (value) => {
      if (config.BIBLE_RE.test(value)) { $scope.requestLocation(value); }
    }
  );

  /**
   * Update the current location via the hbLocationMetadata service..
   *
   * @param {string} [url] A string specifying the URL.
   */
  $scope.requestLocation = (url) => {
    // Get the request string.
    if (angular.isUndefined(url)) { throw 'UndefinedUrlError'; }

    const normalized = normalizeUrl(url);
    const components = urlComponents(normalized);
    const id = components[1];

    // Do not continue if the URL is empty.
    if (id === '') { return; }

    const title = $scope.menu.outline[id].title;
    const chapter = components[2];
    const verse = components[3];

    // Close the navigation popup. Do not reset its location, since the
    // user is likely to want to navigate within the same book.
    $scope.menu.active = false;

    // Then update the app scope's passage.current values to match the
    // requested passage.
    updatePassage({ id, title, chapter, verse });
  };


  /**
   * Query for a given passage using text input.
   * @param {string} text The user-supplied search string.
   */
  $scope.searchPassage = (text) => {
    const passage = $scope.passage.parse(text);
    if (!passage) {
      // TODO: message?
      return;
    }

    $scope.request.book = bookStringToUrl(passage.book);
    $scope.request.chapter = passage.chapter;
    $scope.request.verse = passage.verse;

    const url = buildUrlFromComponents($scope.request);
    $location.path(url);
  };

  /**
   * Take a book string (`Genesis`, `gen`, etc.) and attempt to match it to
   * a book in the outline. If it matches, return the book ID.
   *
   * @param {string} bookString
   * @returns {string}
   * @throws {string} When a request produces an unparseable book.
   */
  function bookStringToUrl(bookString) {
    const bookStringLower = bookString.toLowerCase();

    let bookId;
    for (const b in $scope.outline) {
      const title = $scope.outline[b].title;
      const id = $scope.outline[b].id;

      // For a title or ID to match, the request must be a substring of the
      // title or ID, and they must start with the same character. Thus, Ez
      // will work for Ezekiel, but even though 'John' is a substring of
      // all the epistles as well, it will not match because it does not
      // start with a number. Match against lowercase variants of the strings to
      // handle however it has been typed.
      const titleLower = title.toLowerCase()
        , idLower = id.toLowerCase();

      const titleMatch = (titleLower.indexOf(bookStringLower) !== -1 &&
        titleLower[0] === bookStringLower[0]);

      const idMatch = (idLower.indexOf(bookStringLower) !== -1 &&
        idLower[0] === bookStringLower[0]);

      if (titleMatch || idMatch) {
        return id;
      }
    }

    if (angular.isUndefined(bookId)) {
      throw {
        name: 'UnparseableBook',
        message: `Book ID was undefined: ${bookString}`
      };
    }
  }

  /**
   * Build a request string like `gen.1.1` from book/chapter/verse pieces.
   * @param request A request object with `book` and `chapter` properties
   *     defined, and optionally also a `verse` property defined.
   * @returns {string}
   */
  function buildUrlFromComponents(request) {
    const verse = request.verse ? request.verse : 1;
    const url = `${request.book}.${request.chapter}.${verse}`;
    return url;
  }

  /** Update the location.

    @param {object} passage              Specify the passage.
    @property {string} passage.id        The book ID (e.g. 'gen').
    @property {string} [passage.chapter] The chapter in the book.
    @property {string} [passage.verse]   The verse in the book.

    @note If `chapter` or `verse` are unset, default values (of 1) are used.
   */
  function updatePassage(passage) {
    // Throw exceptions for unset passage IDs or titles.
    if (!passage.id) {
      throw { passage,  name: 'PassageError', message: 'Passage ID not set.' };
    }

    const id = passage.id;

    // Supply default values for unset chapter and verse.
    const chapter = (angular.isDefined(passage.chapter)) ? passage.chapter : '1';
    const verse = (angular.isDefined(passage.verse)) ? passage.verse : '1';

    locationMeta.setPassage({ id, chapter, verse });
  }

  /** Update the search field text to match the current passage.
   */
  function updateSearchField(event, data) {
    const title = data.passage.title,
      chapter = data.passage.chapter,
      verse = data.passage.verse;

    if (!(title && chapter && verse)) {
      $scope.searchField.clear();
    } else {
      $scope.searchField.text = `${title} ${chapter}:${verse}`;
    }
  }

  /** Get the components of a Bible URL request.

    @param {string} url
    @returns {Array} URL components: leading slash, book, chapter, and verse.
   */
  function urlComponents(url) {
    return url.split(/[\.\/]/);
  }

  /** Normalize a user input request string to match the standard URL scheme.

    @param {string} input An input string to normalize.
    @returns {*}
   */
  function normalizeUrl(input) {
    return input.replace(/^([^\/])/, '/$1');
  }

  // Bind public functions to the $scope.
  $scope.cancelBookRequest = cancelBookRequest;
  $scope.requestBook = requestBook;
  $scope.requestChapter = requestChapter;
  $scope.menu.toggleActive = toggleActive;  // TODO: see above at definition.

  // Bind local behavior to events.
  $scope.$on(locationMeta.eventName, updateSearchField);
}


/** Allow the template to filter books by section (i.e. get only New Testament).

  @param {Array} books
  @param {string} section
  @return {Array}
 */
function filterBySection() {
  return (books, section) => {
    const filtered = [];
    for (const b in books) {
      if (books[b].section === section) {
        filtered.push(books[b]);
      }
    }
    return filtered;
  };
}


/** Define a standard range function as a filter.

  @param {Array} range Standard input to a filter; must be an empty array.
  @param {int} max The ending point of the range. Not inclusive by default (see
      inclusive below).
  @param {int} [min] The starting point of the range; defaults to 0.
  @param {bool} [inclusive] Whether to include `max`. Defaults to false.
  @param {int} [step] Step size to use in range.
  @return {Array} An array from min to max by step, including max if
      `inclusive` was set to true.

  @note The first argument is *max*, not *min*. This is so that the function
      can be called as `range(<max>)` rather than having to call
      `range(0, <max>)`.

  @note The types given above are idealized. This is a filter, so all but the
      Array will actually be parsed from a string.
 */
function range() {
  return function (range, max, min, inclusive, step) {
    /* eslint-disable angular/definedundefined */
    min = typeof min !== 'undefined' ? parseInt(min) : 0;
    inclusive = typeof inclusive !== 'undefined' || inclusive === 'true';
    step = typeof step !== 'undefined' ? step : 1;
    /* eslint-enable angular/definedundefined */

    max = parseInt(max);
    if (inclusive) { max += 1; }

    const finalRange = [...range];
    for (let i = min; i < max; i += step) { finalRange.push(i); }
    return finalRange;
  };
}


angular.module('app')
  .controller('BibleNavController', [
    // Inject dependencies. AngularJS:
    '$scope', '$timeout', '$rootElement', '$location',
    // First-party:
    'hbLocationMetadata',
    // Supply the constructor:
    BibleNavController
  ])
  .filter('filterBySection', filterBySection)
  .filter('range', range);
