'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2015/10/23
 * License:
 * Copyright: 2015
 */


function RSSController($resource, $scope) {
  $scope.feeds = null;

  function bindFeeds(feeds) { $scope.feeds = feeds; }

  const Feed = $resource('/f/all');
  Feed.get(bindFeeds);
}


angular.module('app').controller('RSSController', [
  // Inject dependencies.
  '$resource', '$scope',
  // Bind the controller
  RSSController
]);
