'use strict';
/**
 * Angular authentication module.
 *
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/10/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

const hbRequest = require('./_request');

const API_ROOT = '/u';
const HOME = '/';
const PROFILE = '/user/profile';


// Define the authentication service.
function hbUser($http, $location, session) {

  // Supply default post-logout behavior of just returning home. If the user has
  // provided their own behavior, use that instead.
  function withDefaultAfter(callback) {
    function defaultAfter() {
      session.clear();
      $location.path(HOME);
    }

    return callback && angular.isFunction(callback) ? callback : defaultAfter;
  }

  return {
    create(user, afterCreate) {
      const route = `${API_ROOT}/create`;
      return hbRequest(route, 'POST', user, afterCreate, $http);
    },

    destroy(user, afterDestroy) {
      const route = `${API_ROOT}/destroy`;
      return hbRequest(route, 'DELETE', user, withDefaultAfter(afterDestroy), $http);
    },

    forgot(user, afterForgot) {
      const route = `${API_ROOT}/forgot-password`;
      return hbRequest(route, 'POST', user, afterForgot, $http);
    },

    login(user, afterLogin) {
      const route = `${API_ROOT}/login`;
      return hbRequest(route, 'POST', user, afterLogin, $http);
    },

    logout(afterLogout) {
      const route = `${API_ROOT}/logout`;
      hbRequest(route, 'POST', {}, withDefaultAfter(afterLogout), $http);
    },

    reset(user, callback) {
      const route = `${API_ROOT}/password-reset`;
      hbRequest(route, 'PUT', user, callback, $http);
    },

    resendConfirmation(user, callback) {
      const route = `${API_ROOT}/resend-confirmation`;
      hbRequest(route, 'POST', user, callback, $http);
    },

    update(user, callback) {
      // TODO: this should probably error, but for backwards compatibility it
      //       is left as is for the present.
      const configured = user.username || (user.password && user.confirm);
      if (!configured) return;

      const subroute = user.username ? 'username-change' : 'password-change';
      const route = `${API_ROOT}/${subroute}`;
      hbRequest(route, 'PUT', user, callback, $http);
    },

    /**
      Make a function to process a login callback from `hbUser`.

      @param {number} [acceptableStatus] The acceptable status code. If left
          empty, no status code checking is done.
      @param session The hbSession service.
      @param $scope The Angular scope bound to the view.
      @param $location An Angular location.
      @param form An ngForm to reset, and with which to associate error messages.
      @param messages hbMessages instance for "flash"-style messages
      @param clearForm
      @returns {Users~loginHandlerCallback} A function
     */
    makeLoginHandler(acceptableStatus, session, $scope, $location, form, messages, clearForm) {
      /**
        @callback Users~loginHandlerCallback
        @param {Object} data The data returned by the API.
        @param {string} data.username The username returned by the API.
        @param {boolean} data.confirmed Whether the username is confirmed, as
            returned by the API.
        @param {number} data.status The status code returned by the API.
       */
      return (status, data) => {
        // Account for failures in the login action based on the API response.
        const invalidForm =
          (angular.isDefined(acceptableStatus) && status !== acceptableStatus) ||
          angular.isUndefined(data) ||
          angular.isUndefined(data.user) ||
          angular.isUndefined(data.user.username) ||
          angular.isUndefined(data.user.confirmed);

        if (invalidForm) {
          $scope.invalidForm = true;

          messages.authentication = data.reason
            ? data.reason
            : 'The username and password submitted were not valid.';

          form.$setPristine();
          clearForm();
          return;
        }

        // If there are no errors, store the data in the session.
        const sessOpts = {
          user: {
            name: data.user.username,
            confirmed: data.user.confirmed
          },
          access: ['user']
        };

        if (data.user.sb && data.user.sb.hasAccess) {
          sessOpts.access.push('kjvsb');
        }
        session.set(sessOpts);

        // Then go to the user's profile page.
        // TODO: go where the user requested, instead, when using a popup.
        $location.path(PROFILE);
      };
    },

    API_ROOT
  };
}


// Callback documentation.
/**
 * @callback Auth~stdCb
 * @param {number} status The status code returned by the called API.
 * @param {*} data Any data returned by the called API with the status code.
 */


// Define an authentication service for the application.
angular.module('hbServices').factory('hbUser', [
  // Inject dependencies. AngularJS:
  '$http', '$location',
  // Project (`hb`):
  'hbSession',
  hbUser
]);
