/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/6/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

const config = require('../app-config')
  , outline = require('./res/outline')
  , readingSettings = require('./res/reading-settings');

require('./users');

const { isUndefined } = angular;

// Define an Angular controller.
// TODO: Use dependency injection with services for some of these pieces.
function AppController($scope, $location, $log, $route, $window, auth, ACCESS) {
  $scope.$log = $log;

  // Base view data
  $scope.metaTitle = 'HolyBible.com';

  // Bind authorization control.
  $scope.isAuthorized = auth.isAuthorized;
  $scope.userAccess = ACCESS;

  // Set root classes for mobile web app support on iOS devices.
  $scope.mobileWebApp =
    ($window.navigator.standalone !== void 0) && $window.navigator.standalone;
  $scope.mobileWebAppOffset = $scope.mobileWebApp ? 16 : 0;
  $scope.iOS = /i(Pod|Phone|Pad)/g.test($window.navigator.userAgent);

  $scope.templates = config.templates;

  // Outline of the Bible.
  $scope.outline = outline;

  // The current passage. This must be accessible from inheriting scopes
  // since both the navigation template and controller and the main view
  // depend on it.
  // TODO: use local storage and/or cookies to autoload previous passage.
  $scope.passage = {
    book: null,
    setBook(newBook) { this.book = newBook; },

    /**
      Parse a string using normal Bible separation patterns.

      @param {string} passageString A string to parse as a passage.
      @returns {ParsedLocation}
     */
    parse(passageString) {
      const splitters = '[:\\s\\.\\+]';  // Space or punctuation
      const bookNumber = '(?:[1-3]\\s*)';  // Book numbers, like 1th or 1 Thess...
      const bookName = '[A-z]+(?:\\s+[A-z]+)*';  // Book names are one or more words.
      const chapterNumbers = '\\d{1,3}';  // Chapter and verse numbers <= 999.
      const verseNumbers = chapterNumbers;

      // Build a regex by joining the string components with no separator.
      const passageRe = new RegExp(
        ['(', bookNumber, '?', bookName, ')',  // Capture the book (including optional number).
          splitters, '?',  // `jn 1` or `jn1` accepted; do not capture the splitters.
          '(?:',
          '(', chapterNumbers, ')',  // Capture chapter number
          '(?:', splitters, '(', verseNumbers, ')', ')?',  // capture verse number but not splitters
          ')?'
        ].join('')
      );

      const components = passageString.match(passageRe);
      if (isUndefined(components[0]) || isUndefined(components[1])) {
        return null;
      }

      /**
        @typedef {object} ParsedLocation
        @property {string} book
        @property {string} chapter
        @property {string} verse
       */
      return {
        book: components[1],
        chapter: components[2] || '1',
        verse: components[3] || '1',
      };
    }
  };


  // Define the main view so it can be used for the Bible or standalone
  // articles.
  $scope.mainView = {
    // The view type is 'bible' by default; it may also be 'page'.
    type: 'bible',

    // The content defaults to the current passage. It may also be an article.
    content: null,

    /**
     * Set the content, either to the current passage book, or to the value
     * supplied.
     * @param {string} value Either `'bible'` or the desired content. If
     *     `'bible'`, the content will be set to `$scope.passage.book`.
     *     Otherwise, it will be set to the value supplied (presumably, HTML).
     */
    setContent(value)  {
      if (value === 'bible') {
        this.content = $scope.passage.book.content;
        this.type = value;
      }
      else {
        this.content = value;
        this.type = 'page';
      }
    }
  };

  // Define the reading settings. These must be accessible at lower scopes
  // (e.g for the main template and the settings template and controller).
  $scope.readingSettings = readingSettings;

  // Not all settings are popups; create a sub-list of those that are.
  $scope.readingPopupSettings = {};
  for (const key in $scope.readingSettings) {
    if ($scope.readingSettings[key].popup) {
      $scope.readingPopupSettings[key] = $scope.readingSettings[key];
    }
  }

  // Only track analytics on the main website, *not* on test sites.
  function trackPageViews() {
    if ($location.host().match(/holybible\.com/)) {
      $window.ga('send', 'pageview', { page: $location.url() });
    }
  }

  // Bind behavior to events.
  $scope.$on('$viewContentLoaded', trackPageViews);
  $scope.$on('$locationChangeSuccess', trackPageViews);

  $scope.$on('hbLocationMetadataUpdate', (event, data) => {
    $scope.metaTitle = data.meta.title;
  });
}


// Construct a controller for the application.
angular.module('app').controller('AppController', [
  // Inject dependencies. Angular:
  '$scope', '$location', '$log', '$route', '$window',
  // First-party:
  'hbAuth', 'ACCESS', //'hbRouteLogging',  // For debug only.
  // Supply the controller.
  AppController
]);
