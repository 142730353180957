'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2/6/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

function StudyBibleController () {}

angular.module('hbBible').controller('StudyBibleController', [
  // Inject dependencies
  // Supply the controller.
  StudyBibleController
]);
