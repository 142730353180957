'use strict';
/**
  Author: Chris Krycho
  Contact: chris@krycho.com
  Date: 5/22/15
  License: All rights reserved.
  Copyright: 2015 Puritan Reformed Theological Seminary
 */


function HbNotePopover($compile, $document, $window, auth) {
  function controller($scope) {

    /**
      Get the constraining viewport details.
      @returns {{width: Number, height: Number, scrollX: Number, scrollY: Number}}

      The algorithm draws *heavily* on the Bigfoot.js positioning algorithm.
      See [`viewportDetails`](https://github.com/lemonmade/bigfoot/blob/master/src/coffee/bigfoot.coffee#L1119).
      However, it has been adapted to function with the Zepto.js components, so
      that jQuery is not a dependency.
     */
    function viewportDetails($window) {
      const {
        innerWidth: width,
        innerHeight: height,
        scrollX,
        scrollY,
      } = $window;

      return { width, height, scrollX, scrollY };
    }


    const getNavHeight = () =>
      $document[0].querySelector('#mainnav').clientHeight + 2;

    const getSettingsHeight = () => {
      const item = $document[0].querySelector('#settings');
      const { bottom } = $window.getComputedStyle(item);
      const bottomAsNum = bottom.replace(/(\d+).*/, '$1');
      const bottomIsSetToNum = !isNaN(parseInt(bottomAsNum));
      return bottomIsSetToNum ? item.clientHeight : 0;
    };

    const getStripeHeight =
      () => $document[0].querySelector('.branding-stripe').clientHeight;

    const getUnavailableBottomSpace =
      () => getSettingsHeight() + getStripeHeight();

    /**
      Determine how much room is present in each direction around the element.
     */
    const calcRoom = function(el) {
      const v = viewportDetails($window);
      const rect = el.getBoundingClientRect();
      const docEl = el.ownerDocument.documentElement;
      const elOffset = {
        top: rect.top + $window.pageYOffset - docEl.clientTop ,
        left: rect.left + $window.pageXOffset - docEl.clientLeft,
      };
      const elTopInView = elOffset.top - v.scrollY;
      const elLeftInView = elOffset.left - v.scrollX;

      const navBarHeight = docEl.querySelector('#mainnav').clientHeight;
      const topRoom = elTopInView + el.clientHeight / 2 - navBarHeight;

      const bottomRoom = v.height - topRoom - getUnavailableBottomSpace();

      return {
        top: topRoom,
        bottom: bottomRoom,
        leftRelative: elLeftInView / v.width,
      };
    };

    /**
      Determine where to place the popover, and apply CSS accordingly.
     */
    const positionPopover = function(el) {
      const $el = angular.element(el);
      const $link = $el.parent()[0].querySelector('[data-hb-note-link]');
      const content = el.querySelector('.note-content');
      const linkLeft = $link.getBoundingClientRect().left;
      const room = calcRoom($link);
      const spacing = 16;

      // Use el.height() * 1.5 so that it does not end up compressed out of
      // visibility on the bottom of the screen.
      const requiredBottomSpace = el.clientHeight * 1.5;
      const positionAbove = room.bottom < requiredBottomSpace && room.top > room.bottom;

      const offset = '1.5em';
      const [maxHeight, position] = positionAbove
        ? [`${room.top - spacing - getNavHeight()}px`, { bottom: offset }]
        : [`${room.bottom - spacing - getUnavailableBottomSpace()}px`, { top: offset }];

      const articleWidth = document.querySelector('article.book').clientWidth;
      const totalAvailableWidth = Math.max(
        articleWidth,
        document.querySelector('body').clientWidth);

      const left = (el.clientWidth + linkLeft) > totalAvailableWidth
        ? `${(spacing - (room.leftRelative * articleWidth))}px`
        : undefined;

      $el.css(angular.merge({}, { 'max-height': maxHeight }, position, { left }));
      angular.element(content).css({ 'max-height': `calc(${maxHeight} - 1.2rem)` });
    };


    /**
      Show or hide a note popup.
     */
    $scope.toggleNote = function() {
      const closeButton = `
        <div class='close button' data-ng-click='toggleNote()' data-dismiss='modal'>
          <label class='icon-label'>Close</label>
          <div class='icon-wrap'>
            <svg class='svg icon'><use xlink:href='#close-icon'/></svg>
            <div class='svg-fallback icon' data-src='#close-icon'></div>
          </div>
        </div>
      `;

      // Toggle the visibility.
      $scope.note.visible = !$scope.note.visible;

      // If the popover exists, remove it. Otherwise, create it.
      if (!$scope.popover) {
        const popover = $document[0].createElement('div');
        popover.setAttribute('class', 'note-popover modal');

        // Build the popover: get its content, then bind it to the Angular scope
        // and insert it into the DOM.
        const sourceHTML = $scope.popoverSource.innerHTML;
        popover.innerHTML = `
          ${closeButton}
          <div class='note-content' data-hb-note-content>
            ${sourceHTML}
          </div>
        `;

        $compile(popover)($scope);
        $scope.iEl[0].appendChild(popover);

        // Bind the new element to the scope so it can be retrieved later.
        $scope.popover = popover;

        // Set the correct position of the popover.
        positionPopover(popover);
      }
      else {
        $scope.popover.parentElement.removeChild($scope.popover);
        delete($scope.popover);
      }
    };
  }

  function link(scope, iEl, iAttrs) {
    // Don't do anything at all if the user isn't authorized for the KJVSB.
    if (!auth.isAuthorized('kjvsb')) { return; }

    // Don't do anything if there aren't any notes.
    if (angular.isUndefined(scope.textNotes)) { return; }

    // Bind the element reference for ease of use with position calculations.
    scope.iEl = iEl;

    // Bind a reference to the element target for styling.
    scope.popoverSource = $document[0].getElementById(iAttrs.noteHref);

    // Initialize scope settings for each instance.
    scope.note = scope.textNotes[iAttrs.noteReference];
  }

  return {
    controller,
    link,
    restrict: 'A',
    scope: true
  };
}


angular.module('hbBible')
  .directive('hbNotePopover', [
    // Inject dependencies. AngularJS:
    '$compile', '$document', '$window',
    // First-party (hb):
    'hbAuth',
    // Supply the constructor
    HbNotePopover
  ]);
