'use strict';
/**
  Author: Chris Krycho
  Contact: chris@krycho.com
  Date: 7/10/15
  License:
  Copyright: 2015
 */

const OUTLINE = require('../components/res/outline');

/** The HolyBible metadata location service.

  @class hbLocationMetadata
  @param $rootScope An Angular `$rootScope` instance.
  @returns {{setPage: setPage, setPassage: setPassage}}
 */
function hbLocationMetadata($rootScope) {
  // Service constants.
  const EVENT_NAME = 'hbLocationMetadataUpdate';
  const ROOT_TITLE = 'HolyBible.com';

  // Helper data for the service.
  const broadcastProto = { meta: {}, passage: {} };
  const blankPassage = { id: null, title: null, chapter: null, verse: null };

  // Service data
  const meta = { title: ROOT_TITLE };
  const passage = {
    current: angular.copy(blankPassage),
    previous: angular.copy(blankPassage)
  };


  // Function prototypes to export.
  /** Get the location metadata.

    @returns {hbLocationMetadata~LocationMeta}
             An object with the location information.
   */
  function get() {
    return { meta, passage };
  }

  /** Set the metadata for a "page".

    @param {object} pageMeta
    @param {string} pageMeta.title The title of the page.
   */
  function setPage(pageMeta) {
    if (!pageMeta) { return; }

    const broadcast = angular.copy(broadcastProto);
    if (pageMeta.title) {
      meta.title = pageMeta.title;
      if (!meta.title.match(/ – HolyBible\.com/)) {
        meta.title += ' – HolyBible.com';
      }

      broadcast.meta.title = meta.title;
      passage.previous = angular.copy(passage.current);
      passage.current = angular.copy(blankPassage);
    }

    $rootScope.$broadcast(EVENT_NAME, broadcast);
  }

  /** Set the metadata for a "passage".

    @param {object} [passageMeta]
    @param {string} [passageMeta.id] The ID of the book ("gen").
    @param {string|number} [passageMeta.chapter] The chapter of the passage.
    @param {string|number} [passageMeta.chapter] The verse of the passage.
    @fires hbLocationMetadata#hbLocationMetadataUpdate

    @note All the attributes and the object itself are optional, but of course
          no changes will be made and no broadcast emitted
   */
  function setPassage(passageMeta) {
    if (!passageMeta || angular.equals(passageMeta, {})) { return; }

    // Update the previous passage to the passage current before updating.
    passage.previous = angular.copy(passage.current);

    // Build up the components to change
    const broadcast = angular.copy(broadcastProto);

    if (passageMeta.id) {
      passage.current.id = passageMeta.id;
      passage.current.title = OUTLINE[passage.current.id].title;
      const id = passage.current.id;
      const title = passage.current.title;

      angular.extend(broadcast.passage, { id, title });
    }

    if (passageMeta.chapter) {
      passage.current.chapter = passageMeta.chapter;
      angular.extend(broadcast.passage, { chapter: passageMeta.chapter });
    }

    if (passageMeta.verse) {
      passage.current.verse = passageMeta.verse;
      angular.extend(broadcast.passage, { verse: passageMeta.verse });
    }

    if (!angular.equals(broadcast, broadcastProto)) {
      const title =
        passage.current.title + ' ' +
        passage.current.chapter + ':' +
        passage.current.verse + ' - HolyBible.com';

      meta.title = title;
      angular.extend(broadcast.meta, { title });

      $rootScope.$broadcast(EVENT_NAME, broadcast);
    }
  }

  return { get, setPage, setPassage, eventName: EVENT_NAME };
}

angular.module('hbServices').factory('hbLocationMetadata', [
  // Inject dependencies. AngularJS:
  '$rootScope',
  // First party:
  // Supply the constructor.
  hbLocationMetadata
]);


// Document types used in the module.
/** @event hbLocationMetadata#hbLocationMetadataUpdate

  @type {object}

  @property {object} meta              Page metadata
  @property {string} [meta.title]      The new meta title for the page.
  @property {object} passage           The Bible passage.
  @property {string} [passage.title]   The title of the Bible book ("Genesis").
  @property {string} [passage.id]      The ID of the Bible book ("gen").
  @property {string} [passage.chapter] The chapter of the book.
  @property {string} [passage.verse]   The chapter of the book.
 */

/** @typedef {Object} hbLocationMetadata~LocationMeta

  @property {object} meta
            The location's metadata (title, etc.).
  @property {object} passage
            The location information, with `current` and `previous` keys
            specifying corresponding `PassageInfo` data.
  @property {hbLocationMetadata~PassageInfo} passage.current
  @property {hbLocationMetadata~PassageInfo} passage.previous
 */

/** @typedef {object} hbLocationMetadata~PassageInfo

  @property {string} id
  @property {string} title
  @property {string} chapter
  @property {string} verse
 */
