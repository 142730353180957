'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/6/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

// Get metadata useful for content.
const articlesOutline = require('./components/res/articles-outline');

// Module-level constants.
const sbRoot = '/rhkjvsb';

// 'Paths' to templates, so they do not have to be hard coded into the index.
// Note that these are actually paths to the templates loaded into the template
// cache via building each of them into `templates.build.js`. The templates are
// compiled via the `angular-cache` Gulp task. Partials, by contrast, are *not*
// compiled into the templates. They can be loaded dynamically, with
// server-side caching for load time issues.
const templates = {
  bible: 'main/bible.html',
  nav: 'navigation/navigation.html',
  settings: 'settings/settings.html',
  svg: 'res/svg-defs.svg',
  welcome: 'main/welcome.html'
};


// Define user roles
const ACCESS = {
  any: '*',
  user: 'user',
  kjvsb: 'kjvsb'
};


// Define the standard Bible URL.
const BIBLE_RE = /^\/[A-z0-9]{3}\.\d+\.\d+/;


function _sameBook(prev, curr) {
  return prev.split('.')[0] === curr.split('.')[0];
}


const justData = (data/*, status, headers, config*/) => data;
const allArgs = (/*data, status, headers, config*/) => arguments;

/**
  Define a resolve function to use in getting the book in a route.
  @param $http Angular HTTP request service.
  @param $q Angular promise service.
  @param $route Angular "current" route data.
  @param $routeParams Angular future route data.
  @returns {$q.promise} A promise which resolves as the content of the book
      requested or `null`.
 */
function passageResolver($http, $q, $route, $routeParams) {
  const url = $route.current.params.passage;
  const prev = $routeParams.passage !== void 0 ? $routeParams.passage : '';

  // Check whether to load a new book or simply let the view navigate within
  // the book.
  return _sameBook(prev, url) ? $q.resolve(null) : $http.get(`b/${url}`);
}


function makeQueryParams(keyName) {
  return function queryParams($location) {
    return $location.search()[keyName];
  };
}


/**
  Define a resolve function to use in getting the notes associated with a book.
  @param $http Angular HTTP request service.
  @param $q Angular promise service.
  @param $route Angular "current" route data.
  @param $routeParams Angular future route data.
  @returns {$q.promise} A promise which resolves as the book's associated notes
      or `null` if the book has not changed.
 */
function notesResolver($http, $q, $route, $routeParams) {
  const url = $route.current.params.passage;
  const prev = angular.isDefined($routeParams.passage) ? $routeParams.passage : '';

  const resUrl = '/res' + sbRoot;
  const book = url.split('.')[0];
  const bookNotes = resUrl + '/' + book + '.json';

  if (_sameBook(prev, url)) { return $q.defer().resolve(null).promise; }
  else {
    return $http.get(bookNotes)
      .then(justData )
      .catch(allArgs);
  }
}


/**
  Construct a function to resolve the data access list.
  @param accessList
  @returns {Function}
 */
function requiredAccess(accessList) {
  if (!angular.isArray(accessList)) { accessList = [accessList]; }
  return function () { return accessList; };
}


/**
  A not-very-good (but good enough for our purposes) title-caser.
  @param {string} text The text to title-case.
  @return {string} The title-cased text.
 */
function titleCase(text) {
  text = text.replace(/(\w)-(\w)/g, '$1 $2');
  text = text.replace(/\w+/, (subtext) => {
    return subtext.charAt(0).toUpperCase() + subtext.substr(1);
  });

  return text;
}


function pageTitle($route, locationMeta, titleOverride) {
  let title = 'HolyBible.com';

  if (titleOverride) { title = titleOverride; }
  else if (!!$route && !!$route.current.params.page) {
    title = $route.current.params.page;
    title = titleCase(title);
  }

  if (title) { locationMeta.setPage({ title }); }
  return title;
}


function studyBibleTitle($route, locationMeta) {
  const articles = articlesOutline.byUrl;

  const params = $route.current.params ? $route.current.params : null;
  const section = !!params && !!params.section ? params.section : null;
  const article = !!params && params.article ? params.article : null;

  let path;
  if (article) { path = sbRoot + '/' + article; }
  else if (section) { path = sbRoot + '/' + section; }

  // Set the content title from the outline if it is available; otherwise,
  // provide a reasonable fallback.
  const contentTitle = articles[path].title ? articles[path].title : 'RHKJVSB';
  return pageTitle($route, locationMeta, `${contentTitle} – HolyBible.com`);
}


/**
  Return a function that calls pageTitle with a titleOverride value.
  @param  {string} override The override title to use with pageTitle.
  @return {function} A function taking `$route` and `hbLocationMetadata` types
                     as arguments.
 */
function overrideTitleFactory(override) {
  return function($route, locationMeta) {
    return pageTitle($route, locationMeta, override);
  };
}


// Routing configurator to use with the `angular.module.config()` method.
function configure($locationProvider, $routeProvider, ACCESS) {
  // Set up route bindings. Start with the `/pages` routes.
  $routeProvider
    .when('/pages/rhkjvsb-info', {
      reloadOnSearch: false,
      resolve: {
        metaTitle: [
          '$route',
          'hbLocationMetadata',
          overrideTitleFactory('Reformation Heritage KJV Study Bible Info')
        ],
        requiredAccess: requiredAccess([ACCESS.user])
      },
      templateUrl(/*params*/) {
        return '/partials/pages/rhkjvsb-info';
      }
    })

    .when('/pages/:page', {
      controller: 'PageController',
      reloadOnSearch: false,
      resolve: {
        metaTitle: ['$route', 'hbLocationMetadata', pageTitle],
        requiredAccess: requiredAccess([ACCESS.any])
      },
      templateUrl(params) {
        return '/partials/pages/' + params.page;
      }
    })

    // Handling for `/user` routes that do *not* need to be logged in. All user
    // routes use the `UsersController` by default; the templates may further
    // specify a controller.
    .when('/user/password-reset', {
      controller: 'UsersController',
      reloadOnSearch: false,
      resolve: {
        metaTitle: [
          '$route',
          'hbLocationMetadata',
          overrideTitleFactory('Password Reset')
        ],
        requiredAccess: requiredAccess([ACCESS.any])
      },
      templateUrl: '/partials/user/password-reset'
    })

    .when('/user/sign-in', {
      controller: 'UsersController',
      reloadOnSearch: false,
      resolve: {
        metaTitle: [
          '$route',
          'hbLocationMetadata',
          overrideTitleFactory('Sign In')
        ],
        requiredAccess: requiredAccess([ACCESS.any])
      },
      templateUrl: '/partials/user/sign-in'
    })

    .when('/user/confirm-username', {
      controller: 'ConfirmationController',
      reloadOnSearch: false,
      resolve: {
        metaTitle: ['$route', 'hbLocationMetadata', pageTitle],
        username: ['$location', makeQueryParams('username')],
        token: ['$location', makeQueryParams('token')],
        requiredAccess: requiredAccess([ACCESS.any])
      },
      templateUrl: '/partials/user/confirm-username'
    })

    // Handling for other `/user` routes, which *do* require authentication. The
    // controller must be specified by the partial.
    .when('/user/:page', {
      reloadOnSearch: false,
      resolve: {
        metaTitle: ['$route', 'hbLocationMetadata', pageTitle],
        requiredAccess: requiredAccess([ACCESS.user])
      },
      templateUrl(params) {
        return '/partials/user/' + params.page;
      }
    })

    // Study Bible articles
    .when(sbRoot, {
      controller: 'StudyBibleController',
      reloadOnSearch: false,
      resolve: {
        metaTitle: [
          '$route',
          'hbLocationMetadata',
          overrideTitleFactory('Reformation Heritage KJV Study Bible')
        ],
        requiredAccess: requiredAccess([ACCESS.any])
      },
      templateUrl: '/partials' + sbRoot
    })

    .when(sbRoot + '/:section*', {
      controller: 'StudyBibleController',
      reloadOnSearch: false,
      resolve: {
        metaTitle: ['$route', 'hbLocationMetadata', studyBibleTitle],
        requiredAccess: requiredAccess([ACCESS.user, ACCESS.kjvsb])
      },
      templateUrl(params) {
        const root = `/partials${sbRoot}`;
        const path = `${root}/${params.section}`;
        return path;
      }
    })

    // Default handling at the root is for the Bible.
    .when('/:passage', {
      controller: 'BibleController',
      reloadOnSearch: false,
      resolve: {
        book: [
          // Inject dependencies. AngularJS:
          '$http', '$q', '$route', '$routeParams',
          // Supply the function.
          passageResolver
        ],
        notes: [
          // Inject dependencies. AngularJS:
          '$http', '$q', '$route', '$routeParams',
          // Supply the function.
          notesResolver
        ],
        requiredAccess: requiredAccess([ACCESS.any])
      },
      templateUrl: templates.bible
    })

    // In the general case, just redirect to Genesis 1:1
    .otherwise({
      redirectTo: 'gen.1.1',
      reloadOnSearch: false,
      resolve: {
        requiredAccess: requiredAccess([ACCESS.any])
      }
    });


  // Configure HTML5 linking.
  $locationProvider.html5Mode({ enabled: true, requireBase: false });
}


module.exports = { BIBLE_RE, ACCESS, configure, templates };
