'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/8/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

// Module-level constants.
const HOME = '/';


// Define an Angular controller.
function ProfileController (
    $location, $scope, $timeout, messages, session, user) {

  const sessionData = session.get();
  if (sessionData && sessionData.user) {
    $scope.user = {
      username: sessionData.user.name,
      confirmed: sessionData.user.confirmed
    };
  }

  // Bind the local scope to the hgMessages singleton.
  $scope.messages = messages;

  // Enable the controller to track the previous page, for use in
  // conjunction with authentication.
  $scope.previousPage = null;

  // Let the user log out.
  $scope.logout = user.logout;

  /**
   * Process a form requesting deletion of a user.
   *
   * Prompt the user for confirmation, since this is a destructive
   * action; also require that the user provide
   */
  $scope.destroy = function () {
    session.clear();
    user.destroy($scope.user, () => { $location.path(HOME); });
  };

  $scope.resendConfirmation = () => {
    const { username } = $scope.user
    user.resendConfirmation({ username }, () => {
      $scope.user.resentConfirmation = true;
      messages.resentConfirmation =
        'We\'ve sent you a new email to confirm your username! If you don\'t ' +
        'see it, please check your spam folder.';
      $scope.emailForm.$setPristine();
    })
  };

  // Process a form requesting password change by a logged-in user.
  $scope.updatePassword = function () {
    const { password, confirm } = $scope.user;
    user.update({ password, confirm }, (status, data) => {
      if (status === 200 && data.succeeded) {
        messages.passwordUpdate = 'Your password was updated successfully.';
        $scope.user.password = '';
        $scope.user.confirm = '';
        $scope.passwordForm.$setPristine();

        // Only display the success message for a few seconds.
        $timeout(() => { messages.passwordUpdate = ''; }, 5000);
      }
      else {
        messages.passwordUpdate =
          'Sorry, there was a problem updating your password. Please try ' +
          'again. If the problem persists, please send an email describing ' +
          'the problem and including your current username to ' +
          '<a href="mailto:holybible@heritagebooks.org">holybible@heritagebooks.org</a>.';
      }
    });
  };

  // Process a form requesting username change.
  $scope.updateUser = function () {
    const { username } = $scope.user;
    if (username !== session.get().user.name) {
      session.set({ user: { confirmed: false } });
    }

    user.update({ username }, (status, data) => {
      messages.usernameUpdate =
        ([200, 201].includes(status) && angular.isDefined(data.sent))
        ? 'Thanks! You should receive an email shortly asking you to confirm ' +
          'your new email address.'
        : 'We seem to have had a minor problem sending you an email. Please ' +
          'try submitting the request again. If you see this message again, ' +
          'send us an email at holybible@heritagebooks.org';
    });
  };
}


// Bind the controller function to the Angular module.
angular.module('hbUsers').controller('ProfileController', [
  // Inject dependencies. Angular:
  '$location', '$scope', '$timeout',
  // Project (`hb`):
  'hbMessages', 'hbSession', 'hbUser',
  // Bind the controller function.
  ProfileController
]);
