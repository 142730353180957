'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/9/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

/**
 * Helper function to let us use the method-specific callback to operate on a
 * standard HTTP request error callback signature.
 * @param {Auth~stdCb} callback
 * @returns {Function} A function taking the standard Angular HTTP request
 *     promise signature of `data, status, headers, config`.
 */
const _makeResponseHandler =
  (callback) => ({ data, status }) => callback(status, data);


/**
 * Helper function for common HTTP request functionality.
 *
 * @param {string} route
 * @param {string} method
 * @param {Object} data
 * @param {Auth~stdCb} callback
 * @param $http The Angular HTTP service.
 * @returns {Function} A function that runs the relevant HTTP request with
 *     success and error handling bound.
 */
function hbRequest(route, method, data, callback, $http) {
  const cb = callback || angular.noop
    , responseHandler = _makeResponseHandler(cb);

  const req = {
    method, data,
    url: route,
    withCredentials: true
  };

  $http(req)
    .then(responseHandler)
    .catch(responseHandler);
}


module.exports = hbRequest;
