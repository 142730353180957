'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2/6/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */
/* eslint-disable angular/log, no-console, no-unused-vars */

function hbRouteLogging($rootScope) {
  const changeStart = $rootScope.$on('$locationChangeStart', () => {
    console.log('Started location change with arguments: ', arguments);
  });

  const locationChangeSuccess = $rootScope.$on('$locationChangeSuccess', () => {
    console.log('Started location change with arguments: ', arguments);
  });

  const routeChangeStart = $rootScope.$on('$routeChangeStart', () => {
    console.log('Started route change with arguments: ', arguments);
  });

  const routeChangeSuccess = $rootScope.$on('$routeChangeSuccess', () => {
    console.log('Started route change with arguments: ', arguments);
  });

  const routeChangeError = $rootScope.$on('$routeChangeError', () => {
    console.log('Started location change with arguments: ', arguments);
  });

  $rootScope.$on('$destroy', changeStart);
  $rootScope.$on('$destroy', locationChangeSuccess);
  $rootScope.$on('$destroy', routeChangeStart);
  $rootScope.$on('$destroy', routeChangeSuccess);
  $rootScope.$on('$destroy', routeChangeError);

  return {};
}


angular.module('hbServices').factory('hbRouteLogging', [
  // Inject dependencies. Angular:
  '$rootScope',
  // Supply the service
  hbRouteLogging
]);
