'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/16/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

// Define an Angular module for all the Bible components.
angular.module('hbBible', ['cgBusy', 'duScroll']);

// The Browserify package includes all JS modules that comprise the AngularJS
// `hbBible` module.
require('./bible-controller');
require('./page-controller');
require('./rss-controller');
require('./study-bible-controller');
require('./related-articles-directive');
require('./text-notes-directive');
require('./popover-directive');
