module.exports = {
  'typesize': {
    'order': 0,
    'id': 'typesize',
    'description': 'Type Size',
    'type': 'iterable',
    'active': false,
    'popup': true,
    'options': {
      'micro': {'order': 0, 'id': 'micro', 'description': 'Micro', 'active': false, 'class': 'type-micro'},
      'tiny': {'order': 1, 'id': 'tiny', 'description': 'Tiny', 'active': false, 'class': 'type-tiny'},
      'small': {'order': 2, 'id': 'small', 'description': 'Small', 'active': false, 'class': 'type-small'},
      'normal': {'order': 3, 'id': 'normal', 'description': 'Normal', 'active': true, 'class': 'type-normal'},
      'large': {'order': 4, 'id': 'large', 'description': 'Large', 'active': false, 'class': 'type-large'},
      'huge': {'order': 5, 'id': 'huge', 'description': 'Huge', 'active': false, 'class': 'type-huge'},
      'mega': {'order': 6, 'id': 'mega', 'description': 'Mega', 'active': false, 'class': 'type-mega'}
    },
    'currentOption': 'normal',
    'defaultOption': 'normal',
    'maxOption': 'mega',
    'minOption': 'micro',
    'numOptions': 7
  },
  'lineheight': {
    'order': 1,
    'id': 'lineheight',
    'description': 'Line Height',
    'type': 'iterable',
    'active': false,
    'popup': true,
    'options': {
      'tiny': {'order': 0, 'id': 'tiny', 'description': 'Tiny', 'active': false, 'class': 'lh-tiny'},
      'small': {'order': 1, 'id': 'small', 'description': 'Small', 'active': false, 'class': 'lh-small'},
      'normal': {'order': 2, 'id': 'normal', 'description': 'Normal', 'active': true, 'class': 'lh-normal'},
      'large': {'order': 3, 'id': 'large', 'description': 'Large', 'active': false, 'class': 'lh-large'},
      'huge': {'order': 4, 'id': 'huge', 'description': 'Huge', 'active': false, 'class': 'lh-huge'}
    },
    'currentOption': 'normal',
    'defaultOption': 'normal',
    'maxOption': 'huge',
    'minOption': 'tiny',
    'numOptions': 5
  },
  'contrast': {
    'order': 2,
    'id': 'contrast',
    'description': 'Contrast',
    'class': 'contrast',
    'type': 'choosable',
    'active': false,
    'popup': true,
    'options': {
      'light': {'order': 0, 'id': 'light', 'description': 'Light', 'active': false, 'class': 'light'},
      'sepia': {'order': 1, 'id': 'sepia', 'description': 'Sepia', 'active': true, 'class': 'sepia'},
      'dark': {'order': 2, 'id': 'dark', 'description': 'Dark', 'active': false, 'class': 'dark'}
    },
    'currentOption': 'light',
    'numOptions': 3
  },
  'reading': {
    'order': 3,
    'id': 'reading',
    'description': 'Reading Mode',
    'type': 'togglable',
    'popup': false,
    'active': false
  }
};
