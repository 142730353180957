'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/8/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */


// Module-level constants
const PROFILE = '/user/profile';


/**
 * Check whether username change tokens are valid.
 * @param $http The Angular `$http` service.
 * @param change The change request token, containing a token and username.
 * @param user The local user management service.
 * @returns A Promise with the result
 */
function _tokenValid($http, change, user) {
  const url = `${user.API_ROOT}/confirm-username`;
  return $http.put(url, change, { timeout: 5000 });
}


function ConfirmationController($http, $location, $sce, $scope, session, user, token, username) {
  // Users may already have confirmed their username.
  const maybeSession = session.get();
  if (maybeSession && maybeSession.user && maybeSession.user.confirmed) {
    $location.url(PROFILE);
  }

  // The username and token items are injected via the route resolution.
  _tokenValid($http, { username, token }, user)
    .then((/*response*/) => {
      $scope.success = true;
      session.set({ user: { name: username, confirmed: true } });
    })
    .catch((err) => {
      $scope.success = false;
      $scope.noChangeReason = err.data.reason ||
        'There was a problem attempting to update your username.' +
        'Please return to your <a href="' + PROFILE + '">profile</a> ' +
        'and try again. If this happens again, send an email to ' +
        '<a href="mailto:holybible@heritagebooks.org">holybible@heritagebooks.org</a>. ' +
        'We apologize for the inconvenience!';
    });
}


angular.module('hbUsers').controller('ConfirmationController', [
  // Inject dependencies. Angular:
  '$http', '$location', '$sce', '$scope',
  // First-party:
  'hbSession', 'hbUser',
  // Injected by route resolver:
  'token', 'username',
  // Bind the controller constructor.
  ConfirmationController
]);
