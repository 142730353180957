'use strict';

// Define requirements for Browserify.
require('../app-controller');

// siteOutline -> boolean -> isProfile -> siteOutline
const setProfileViews = (original, isAuthenticated, isProfile) => angular.merge(
  {},
  original,
  {
    pages: {
      profile: {
        show: isAuthenticated && !isProfile,
      },
      signIn: {
        show: !isAuthenticated,
      },
      signOut: {
        show: isAuthenticated && isProfile,
      },
    },
  }
);

function SiteNavController($location, $scope, $window, locationMeta, session, user) {
  const defaultClick = () => $scope.sitenav.toggleActive(false);

  const updateOutline = (/*event, eventData*/) => {
    const isAuthenticated = session.isAuthenticated();
    const isProfile = /^Profile/.test(locationMeta.get().meta.title);
    $scope.siteOutline = setProfileViews(siteOutline, isAuthenticated, isProfile);
  }

  const siteOutline = {
    pages: {
      read: {
        title: 'Read',
        click: defaultClick,
        standalone: false,
        url: '/gen.1.1',
        order: 0,
        menu: true,
        show: true,
        id: 'read',
      },
      about: {
        title: 'About',
        click: defaultClick,
        standalone: true,
        url: 'about',
        order: 1,
        menu: true,
        show: true,
        id: 'about',
      },
      studyBible: {
        title: 'Study Bible',
        click: defaultClick,
        standalone: false,
        order: 2,
        menu: true,
        url: '/rhkjvsb',
        show: true,
        id: 'study-bible',
      },
      resource: {
        title: 'Resources',
        click: defaultClick,
        standalone: true,
        url: 'resources',
        order: 2,
        menu: true,
        show: true,
        id: 'resources',
      },
      profile: {
        title: 'Account',
        click: defaultClick,
        standalone: false,
        url: '/user/profile',
        order: 3,
        menu: true,
        show: false,
        id: 'profile',
      },
      signIn: {
        title: 'Sign In',
        click: defaultClick,
        standalone: false,
        url: '/user/sign-in',
        order: 4,
        menu: true,
        show: true,
        id: 'sign-in',
      },
      signOut: {
        title: 'Sign Out',
        click: () => {
          defaultClick();
          user.logout();
        },
        standalone: false,
        url: '/',
        order: 5,
        menu: true,
        show: false,
        id: 'sign-out',
      },
    },

    getId(page) {
      return `sitenav-${page.id}`;
    },

    getUrl(page) {
      if (page.standalone) { return '/pages/' + page.url; }
      else { return page.url; }
    },

    runClick(page) {
      page.click();
    },
  };

  updateOutline();

  // This is a *horrible* hack. But it gets the job done.
  $scope.clickLogo = () => {
    const showMenu = $window.innerWidth <= 1340;
    if (showMenu) {
      $scope.sitenav.toggleActive();
    } else {
      $location.path($scope.siteOutline.pages.read.url);
    }
  }

  /**
    Update the read link as appropriate, so that it always takes the user back
    to the previous Bible page they were reading.

    @param event
    @param eventData
   */
  const updateReadLink = (event, eventData) => {
    const urlFromPassage = (passage) =>
      '/' + [passage.id, passage.chapter, passage.verse].join('.');

    const { passage } = eventData;
    let updatedUrl = $scope.siteOutline.pages.read.url;
    if (passage && !angular.equals(passage, {})) {
      updatedUrl = urlFromPassage(passage);
    } else {
      const previousPassage = locationMeta.get().passage.previous;
      if (angular.isDefined(previousPassage.id)) {
        updatedUrl = urlFromPassage(previousPassage);
      }
    }

    $scope.siteOutline.pages.read.url = updatedUrl;
  }

  const onChangeLocation = (event, eventData) => {
    updateOutline();
    updateReadLink(event, eventData);
  }

  $scope.$on(locationMeta.eventName, onChangeLocation);
  $scope.$on(session.EVENT, updateOutline);
}

// Define a controller for site navigation
angular.module('app').controller('SiteNavController', [
  // Inject dependencies. AngularJS:
  '$location', '$scope', '$window',
  // First-party
  'hbLocationMetadata', 'hbSession', 'hbUser',
  // Supply the constructor
  SiteNavController]
);
