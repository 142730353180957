/**
  Author: Chris Krycho
  Contact: chris@krycho.com
  Date: 12/8/14
  License: All rights reserved.
  Copyright: 2014 Puritan Reformed Theological Seminary
 */

// Define requirements for Browserify.
require('../app-controller.js');
const articlesOutline = require('../res/articles-outline');

const SHOW_WELCOME = {
  key: 'SHOW_WELCOME',
  show: 'show',
  hide: 'hide',
};

// MUTATING $cookies -> 'show' | 'hide'
const setShowWelcome = ($cookies, value) => {
  if (![SHOW_WELCOME.show, SHOW_WELCOME.hide].includes(value)) {
    throw new Error('Wrong value supplied for setting showWelcome');
  }

  $cookies.put(SHOW_WELCOME.key, value);
}

// $cookies -> boolean
const showWelcome = ($cookies) => {
  switch ($cookies.get(SHOW_WELCOME.key)) {
  case SHOW_WELCOME.hide: return false;
  case SHOW_WELCOME.show: return true;
  default: return true;
  }
}

// MUTATING $cookies
const setupCookies = ($cookies) => {
  if (angular.isUndefined($cookies.get(SHOW_WELCOME.key))) {
    $cookies.put(SHOW_WELCOME.key, SHOW_WELCOME.show);
  }
}

// A controller for the Bible itself.
function BibleController($cookies, $scope, { data: book }, { data: notes }, auth, locationMeta) {
  // Make the related articles outline available.
  $scope.articlesOutline = articlesOutline.byLocation;
  $scope.passageInfo = locationMeta.get().passage.current;
  $scope.sbAccess = auth.isAuthorized('kjvsb') ? 'sb-access' : '';

  setupCookies($cookies);

  $scope.showWelcome = showWelcome($cookies);

  $scope.hideWelcome = () => {
    $scope.showWelcome = false;
    setShowWelcome($cookies, SHOW_WELCOME.hide);
  }

  // If the book is *not* null, the data is available and can be assigned to the
  // passage object. If it is null, no change is needed; new content was not
  // loaded.
  if (book !== null) {
    $scope.passage.setBook(book.content);
    $scope.prev = book.prev;
    $scope.next = book.next;

    // Set a visibility flag on each note item.
    $scope.textNotes = notes.map(n => angular.merge({}, n, { visible: false }));
  }
}


function BookContentController($document, $location, $scope, $timeout) {

  /**
    Maintain the basic scroll position even as the relative position of the
    element on the pages shifts due to changes in reading settings.
   */
  function keepScrollPosition() {
    // Wait for the digest cycle to finish and the browser to have fully
    // rendered the page.
    $timeout(() => {
      const offset = $scope.navOffset + $scope.mobileWebAppOffset;
      const delay = 0;
      const passage = $location.path().replace(/\./g, '-').replace(/^\//, '');
      const dEl = document.getElementById(passage);
      const ngEl = angular.element(dEl);

      $document.scrollToElement(ngEl, offset, delay);
    });
  }

  // Scroll to the chapter specified in the URL when toggling reading settings.
  $scope.$on('hbReadingSettingsChanged', keepScrollPosition);
}


// Construct a controller for cross-book behaviors.


// A controller for the internal content of Bible books.
angular.module('hbBible')
  .controller('BibleController', [
    // Inject dependencies. AngularJS:
    '$cookies', '$scope',
    // First-party:
    'book', 'notes', 'hbAuth', 'hbLocationMetadata',
    // Supply the controller function.
    BibleController
  ])
  .controller('BookContentController', [
    // Inject dependencies. AngularJS:
    '$document', '$location', '$scope', '$timeout',
    // Supply the controller function.
    BookContentController
  ]);
