'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 3/13/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

const EVENT = 'USER_SESSION_CHANGE';

function hbSession($cookies, $rootScope) {
  // Initialize the session with an empty cache.
  let _session = {};
  const COOKIE_SESSION_NAME = 'hbsession';
  const COOKIE_PATH = '/';

  const sessionService = {
    clear() {
      _session = {};
      $cookies.remove(COOKIE_SESSION_NAME, { path: COOKIE_PATH });
      $rootScope.$broadcast(EVENT, EVENT);
    },

    /**
     * Get the user information.
     * @returns {{}|null} The session data, if the session is set; otherwise null.
     */
    get() {
      // Check whether session data exists in the application state or a cookie.
      const cookieSession = $cookies.getObject(COOKIE_SESSION_NAME);

      if (angular.equals(_session, {})) {
        if (angular.equals(cookieSession, {}) || !cookieSession) {
          return null;
        }
        else {
          angular.copy(cookieSession, _session);
        }
      }

      return _session;
    },

    /**
     * @return {Boolean}
     */
    isAuthenticated() {
      // Prime the session.
      sessionService.get();

      const authenticated =
        angular.isDefined(_session.user) &&
        angular.isDefined(_session.user.name) &&
        angular.isDefined(_session.user.confirmed) &&
        angular.isDefined(_session.access);

      return authenticated;
    },

    /**
     * @param {{}} sessOpts
     * @param {{}} sessOpts.user
     * @param {string} sessOpts.user.name
     * @param {boolean} sessOpts.user.confirmed
     * @param {{}} sessOpts.user.access An object describing user access, keyed by
     */
    set(sessOpts) {
      if (!sessOpts) {
        throw new Error('Cannot call hbSession.set without `sessOpts` parameter.');
      }

      angular.copy(sessOpts, _session);

      const month = 30 * 24 * 60 * 60 * 1000;
      const expiration = new Date(+new Date() + month);
      const config = {expires: expiration, path: COOKIE_PATH};

      $cookies.putObject(COOKIE_SESSION_NAME, _session, config);
      $rootScope.$broadcast(EVENT, EVENT);
    },

    EVENT,
  };

  return sessionService;
}


angular.module('hbServices').factory('hbSession', [
  // Inject dependencies. AngularJS:
  '$cookies', '$rootScope',
  // First-party:
  // Supply the constructor
  hbSession
]);
