'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/9/14
 * License:
 * Copyright: 2014
 */


// Define a simple filter to output trusted data -- thus, avoiding the need to
// call `$sce.trustAsHtml` explicitly anywhere it might be relevant.
const trusted = $sce => val => $sce.trustAsHtml(val);

angular.module('app').filter('trusted', ['$sce', trusted]);
