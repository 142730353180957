'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/9/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

const hbRequest = require('./_request');

const API_ROOT = '/r/rhkjvsb';


// Define an Angular controller.
function sbAccess($http) {
  return {
    redeem(key, callback) {
      const route = `${API_ROOT}/redeem`;
      hbRequest(route, 'POST', { key }, callback, $http);
    }
  };
}


// Bind the controller to the module.
angular.module('hbServices').factory('hbSbAccess', [
  // Inject dependencies. Angular:
  '$http',
  // Supply the controller function.
  sbAccess
]);
