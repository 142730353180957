'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2/27/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

/**
 * A simple service to provide a singleton resource for flash messages.
 *
 * @returns {{}} A simple (empty at construction) container object.
 */
function hbMessages () { return {}; }


// Construct the service.
angular.module('hbServices').factory('hbMessages', [ hbMessages ]);
