'use strict';
/**
  Author: Chris Krycho
  Contact: chris@krycho.com
  Date: 1/16/15
  License: All rights reserved.
  Copyright: 2015 Puritan Reformed Theological Seminary
 */

// Define an Angular controller.
function SignupController ($location, $scope, session, user, messages) {
  // Provide an object against which the form can bind.
  $scope.newUser = {};

  // Process a form requesting user registration.
  $scope.create = function (form) {
    const clearForm = () => {
      $scope.newUser = {};
    };

    const handler = user.makeLoginHandler(
      201, session, $scope, $location, form, messages, clearForm);

    user.create($scope.newUser, handler);
  };
}

// Add the controller to the users module.
angular.module('hbUsers').controller('SignupController', [
  // Inject dependencies. Angular:
  '$location', '$scope',
  // Project (`hb`):
  'hbSession', 'hbUser', 'hbMessages',
  // Supply the controller.
  SignupController
]);
