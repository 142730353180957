'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 12/6/14
 * License: All rights reserved.
 * Copyright: 2014 Puritan Reformed Theological Seminary
 */

// Get dependencies for setting up the top-level Angular module.
const config = require('./app-config');

function runApp($http, $location, $rootScope, auth, messages, session) {
  // On startup, check whether the user is authorized on the back end. We
  // fully expect that the result may be negative.
  const ignoreErr = () => {};

  $http.get('/u/logged-in')
    .then((data) => {
      if (data.user) {
        const sessionData = {
          user: {
            name: data.user.username,
            confirmed: data.user.confirmed
          },
          access: ['user']
        };

        if (data.user.sb && data.user.sb.hasAccess) {
          sessionData.access.push('kjvsb');
        }

        session.set(sessionData);
      }
    })
    .catch(ignoreErr);

  function routeChangeHandler(event, next/*, current*/) {
    if (angular.isDefined(next.$$route) &&
        !auth.isAuthorized(next.$$route.resolve.requiredAccess())) {

      // Never go to the relevant page if not authorized.
      event.preventDefault();

      // If the issue is one of authentication, redirect to the login page.
      if (!session.isAuthenticated()) { $location.path('/user/sign-in'); }
      else {
        messages.unauthorized =
          'Sorry, you are not authorized to view that content.';
      }
    }
  }

  const routeChangeWatch = $rootScope.$on('$routeChangeStart', routeChangeHandler);
  $rootScope.$on('$destroy', routeChangeWatch);
}


// Define the top-level module's dependencies and configure it.
angular
  .module('app', [
    // Inject module dependencies. AngularJS:
    'ngCookies', 'ngResource', 'ngRoute', 'ngOrderObjectBy',
    // Third-party:
    'cgBusy', 'duScroll',
    // Project:
    'hbBible', 'hbServices', 'hbUsers'
  ])
  .constant('ACCESS', config.ACCESS)
  .config([
    // Inject provider dependencies for the configuration function. AngularJS:
    '$locationProvider', '$routeProvider',
    // First-party
    'ACCESS',
    // Configure the module.
    config.configure
  ]).value('cgBusyDefaults',{
    message:'Loading...',
    // backdrop: false,
    // templateUrl: 'my_custom_template.html',
    delay: 250,
    minDuration: 1500,
    wrapperClass: 'loading-template'
  }).run([
    // Inject dependencies. AngularJS:
    '$http', '$location', '$rootScope',
    // First-party
    'hbAuth', 'hbMessages', 'hbSession',
    // How to run the application.
    runApp
  ]);

/* Assemble the rest of the application. */
// Common helper functionality.
require('./directives');
require('./filters');
require('./services');
// Views
require('./components/templates.build');
// Controllers
require('./components/app-controller');
require('./components/main');
require('./components/users');
require('./components/navigation');
require('./components/settings');
