'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/16/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

// Define an Angular services module.
angular.module('hbServices', ['ngCookies']);

// The Browserify package includes all modules here. Order matters, because some
// modules depend on others.
require('./auth');
require('./location-metadata');
require('./messages');
require('./sb-access');
require('./session');
require('./route-logging');
require('./user');
