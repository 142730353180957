'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 1/23/15
 * License: All rights reserved.
 * Copyright: 2015 Puritan Reformed Theological Seminary
 */

const { isDefined, isString } = angular;

/**
 * An Angular constructor for the `bible-content` directive.
 * @returns {{}} An Angular definition object.
 * @constructor
 */
function HbMainContent ($document, $q, $timeout) {
  function link (scope/*, el, attrs*/) {

    const scrollToPassage = (event, args) => {
      const passage = args.hasOwnProperty('pathParams') && isDefined(args.pathParams.passage)
        ? args.pathParams.passage
        : isString(args) && /\w+\.\d+(\.\d+)?$/.test(args)
          ? args.split('/').pop()
          : null;

      if (!passage) {
        // For non-Bible pages, we simply need to make sure we are at the top of
        // the page after load.
        $timeout(() => {
          $document.scrollTop(scope.mobileWebAppOffset);
        }, 0, false);

        return;
      }

      // Scroll to the correct location on the page.
      // Use a timeout to make sure document renders first. Set the timeout
      // value to a tenth of a second to account for the "feature" in Chrome
      // that scrolls the user back to their previous position on the page on
      // a time delay after load.
      const scrollAction = () => {
        // If the passage is the first element in a book, just scroll to the
        // top. Otherwise, scroll to the element.
        if (/\.1\.1$/.test(passage)) {
          $document.scrollTopAnimated(0, 500);
        } else {
          const passageID = passage.replace(/\./g, '-');
          const ngEl = angular.element(document.getElementById(passageID));
          const navOffset = document.getElementById('mainnav').offsetHeight
          const offset = navOffset + scope.mobileWebAppOffset + 12;

          // Depending on event fire sequence, this may not have rendered.
          // Don't try to scroll till it does.
          if (ngEl.length === 0) {
            return;
          }

          $document.scrollToElement(ngEl, offset, 500);
        }
      };

      // Use `$timeout` to guarantee that the browser has rendered before trying
      // to scroll. Do it after 100ms to (hope) that fonts have rendered, too.
      $timeout(scrollAction, 100, false);
    };

    scope.$on('$locationChangeSuccess', scrollToPassage);
    scope.$on('$routeChangeSuccess', scrollToPassage);

    const showLoadingIndicator = (/*event*/) => {
      scope.loadingPromise = $q.defer();
    };

    const endLoadingIndicator = (/*event*/) => {
      if (scope.loadingPromise) {
        scope.loadingPromise.resolve();
      }
    }

    scope.$on('$routeChangeStart', showLoadingIndicator);
    scope.$on('$routeChangeSuccess', endLoadingIndicator);
    scope.$on('$routeChangeError', endLoadingIndicator);
  }

  return {
    link,
    restrict: 'A'  // Only allow attribute usage.
  };
}

// Construct the directive and bind it to the `app` module.
angular.module('app')
  .directive('hbMainContent', [
    // Inject dependencies. AngularJS:
    '$document', '$q', '$timeout',
    // Supply the directive.
    HbMainContent
  ]);
