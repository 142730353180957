'use strict';
/**
 * Author: Chris Krycho
 * Contact: chris@krycho.com
 * Date: 2/13/15
 * License:
 * Copyright: 2015
 */
/* globals document */


/**
 * Constructor for the `related-articles` directive.
 *
 * @constructor
 */
function HbRelatedArticles($timeout, auth) {
  function link (scope, el, attrs) {
    // Don't do anything at all if the user isn't authorized for the KJVSB.
    if (!auth.isAuthorized('kjvsb')) { return; }

    const book = attrs.hbRelatedArticles;

    $timeout(() => {
      if (!!scope.articlesOutline && !!scope.articlesOutline[book]) {

        const articles = scope.articlesOutline[book];

        let id;
        for (let article = 0; article < articles.length; article++) {
          let insertBeforeEl;
          let wrapperEl = null;
          // Get the correct insertion location, depending on whether the note
          // is tied to a given chapter or to the book as a whole.
          if (articles[article].chapter) {
            // If to the chapter, insert inline before the first verse.
            id = book + '-' + articles[article].chapter;
            insertBeforeEl = document.getElementById(id);
          }
          else {
            // If to the book, insert before the first paragraph, with a div
            // wrapper for the element.
            id = book;
            insertBeforeEl = document.getElementById(id).firstChild.nextSibling;
            const wrapperId = book + '-book-notes';
            wrapperEl = document.getElementById(wrapperId);
            if (!wrapperEl) {
              wrapperEl = document.createElement('div');
              wrapperEl.setAttribute('id', wrapperId);
              wrapperEl.setAttribute('class', 'book-notes');
            }
          }


          // Define the element to insert:
          //     <a href="{url}" class="related-article">{source}: {title}</a>
          const contentEl = document.createElement('a');
          contentEl.setAttribute('href', articles[article].url);
          contentEl.setAttribute('class', 'related-article');
          const text = `${articles[article].source}:${articles[article].title}`;
          const content = document.createTextNode(text);
          contentEl.appendChild(content);
          if (wrapperEl) {
            wrapperEl.appendChild(contentEl);
            insertBeforeEl.parentNode.insertBefore(wrapperEl, insertBeforeEl);
          }
          else {
            insertBeforeEl.parentNode.insertBefore(contentEl, insertBeforeEl);
          }
        }
      }
    }, 0);
  }

  return { link, restrict: 'A' };
}


// Construct the directive and bind it to the `hbBible` module.
angular.module('hbBible').directive('hbRelatedArticles', [
  // Inject dependencies. AngularJS:
  '$timeout',
  // First-party (hb):
  'hbAuth',
  // Supply the directive.
  HbRelatedArticles
]);
